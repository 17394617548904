define("discourse/plugins/karma-score/discourse/components/profile-summary-votes", ["exports", "@ember/component", "@ember/service", "@ember/object", "discourse/plugins/karma-score/lib/voting-history/index", "discourse/plugins/karma-score/lib/karma-api-client"], function (_exports, _component, _service, _object, _index, _karmaApiClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    router: (0, _service.inject)(),
    profile: {},
    votes: [],
    fetched: false,
    hasSetApiKey: false,
    count: 0,
    daoName: "",
    oldDaoName: "",
    shouldShowActionButtons: (0, _object.computed)(function () {
      return this.session && this.profile.username && this.currentUser && this.profile?.username === this.currentUser?.username;
    }),
    async fetchVotes() {
      (0, _object.set)(this, "fetched", false);
      (0, _object.set)(this, "votes", []);
      const votes = await _index.default.start(this.profile, {
        SiteSettings: this.siteSettings
      });
      (0, _object.set)(this, "fetched", true);
      (0, _object.set)(this, "votes", votes);
    },
    async init() {
      this._super(...arguments);
      this.daoName = this.oldDaoName = window.selectedDao;
      const cli = new _karmaApiClient.default(this.daoName, "");
      if (this.session) {
        try {
          const {
            allowance
          } = await cli.isApiAllowed(this.session.csrfToken);
          (0, _object.set)(this, "hasSetApiKey", !!allowance);
        } catch {}
      }
    },
    async didReceiveAttrs() {
      this._super(...arguments);
      if (this.profile.address && !this.fetched) {
        this.fetchVotes();
      }
    },
    didUpdate() {
      if (this.profile.address && this.oldDaoName.toLowerCase() !== this.daoName.toLowerCase()) {
        (0, _object.set)(this, 'oldDaoName', this.daoName);
        this.fetchVotes();
      }
    }
  });
});