define("discourse/plugins/karma-score/lib/get-number-with-ordinal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getNumberWithOrdinal = getNumberWithOrdinal;
  function getNumberWithOrdinal(n) {
    const s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }
});