define("discourse/plugins/karma-score/lib/snapshot/approval", ["exports", "discourse/plugins/karma-score/lib/snapshot/BaseScore"], function (_exports, _BaseScore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ApprovalVoting extends _BaseScore.BaseScore {
    static isValidChoice() {
      let voteChoice = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      let proposalChoices = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      return Array.isArray(voteChoice) &&
      // If voteChoice index is not in proposalChoices, return false
      voteChoice.every(choice => proposalChoices?.[choice - 1] !== undefined) &&
      // If any voteChoice is duplicated, return false
      voteChoice.length === new Set(voteChoice).size &&
      // If voteChoice is empty, return false
      voteChoice.length > 0;
    }
    getValidVotes() {
      return this.votes.filter(vote => ApprovalVoting.isValidChoice(vote.choice, this.proposal.choices));
    }
    getScores() {
      return this.proposal.choices.map((choice, i) => this.getValidVotes().filter(vote => vote.choice.includes(i + 1)).reduce((a, b) => a + b.balance, 0));
    }
    getScoresByStrategy() {
      return this.proposal.choices.map((choice, i) => this.strategies.map((strategy, sI) => this.getValidVotes().filter(vote => vote.choice.includes(i + 1)).reduce((a, b) => a + b.scores[sI], 0)));
    }
    getScoresTotal() {
      return this.getValidVotes().reduce((a, b) => a + b.balance, 0);
    }
    getChoiceString() {
      if (!this.selected) {
        return "";
      }
      return this.proposal.choices.filter((_, i) => this.selected.includes(i + 1)).join(", ");
    }
  }
  _exports.default = ApprovalVoting;
});