define("discourse/plugins/karma-score/lib/parse-md-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseMdLink = parseMdLink;
  function parseMdLink() {
    let string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
    const regex = /\[(.*?)\]\((.*?)\)/gim;
    const groupRgx = /\[(.*?)\]\((.*?)\)/;
    const myMatch = string.match(regex);
    let parsedString = string;
    if (myMatch) {
      myMatch.forEach(match => {
        const [full, text, url] = match.match(groupRgx);
        if (full && text && url) {
          parsedString = parsedString.replace(full, `<a href="${url}" noopener target="_blank rel="noopener noreferrer"">${text}</a>`).replace(/\\|\#/g, "");
        }
      });
    }
    return parsedString;
  }
});