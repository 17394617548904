define("discourse/plugins/karma-score/lib/snapshot/rankedChoice", ["exports", "discourse/plugins/karma-score/lib/get-number-with-ordinal", "discourse/plugins/karma-score/lib/snapshot/BaseScore"], function (_exports, _getNumberWithOrdinal, _BaseScore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function irv() {
    let ballots = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [[]];
    let rounds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [{
      round: 0,
      sortedByHighest: [["", [0, [0]]]]
    }];
    const candidates = [...new Set(ballots.map(vote => vote[0]).flat())];
    const votes = Object.entries(ballots.reduce((votesAcc, _ref, i, src) => {
      let [v] = _ref;
      const balance = src[i][1];
      votesAcc[v[0]][0] += balance;
      const score = src[i][2];
      if (score.length > 1) {
        votesAcc[v[0]][1] = score.map((s, sI) => s + votesAcc[v[0]][1][sI] || s);
      } else {
        votesAcc[v[0]][1] = [votesAcc[v[0]][1].concat(score).reduce((a, b) => a + b, 0)];
      }
      return votesAcc;
    }, Object.assign({}, ...candidates.map(c => ({
      [c]: [0, []]
    })))));
    const votesWithoutScore = votes.map(vote => [vote[0], vote[1][0]]);
    const [, topCount] = votesWithoutScore.reduce((_ref2, _ref3) => {
      let [n, m] = _ref2;
      let [v, c] = _ref3;
      return c > m ? [v, c] : [n, m];
    }, ["?", -Infinity]);
    const [bottomCand] = votesWithoutScore.reduce((_ref4, _ref5) => {
      let [n, m] = _ref4;
      let [v, c] = _ref5;
      return c < m ? [v, c] : [n, m];
    }, ["?", Infinity]);
    const sortedByHighest = votes.sort((a, b) => b[1][0] - a[1][0]);
    const totalPowerOfVotes = ballots.map(bal => bal[1]).reduce((a, b) => a + b, 0);
    rounds.push({
      round: rounds.length + 1,
      sortedByHighest
    });
    return topCount > totalPowerOfVotes / 2 || sortedByHighest.length < 3 ? rounds : irv(ballots.map(ballot => [
    // eslint-disable-next-line eqeqeq
    ballot[0].filter(c => c != bottomCand), ballot[1], ballot[2]]).filter(ballot => ballot[0].length > 0), rounds);
  }
  function getFinalRound() {
    let votes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    const rounds = irv(votes.map(vote => [vote.choice, vote.balance, vote.scores]), []);
    const finalRound = rounds[rounds.length - 1];
    return finalRound.sortedByHighest;
  }
  class RankedChoiceVoting extends _BaseScore.BaseScore {
    static isValidChoice() {
      let voteChoice = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [0];
      let proposalChoices = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [""];
      return Array.isArray(voteChoice) &&
      // If voteChoice index is not in choices, return false
      voteChoice.every(vc => proposalChoices?.[vc - 1] !== undefined) &&
      // If any voteChoice is duplicated, return false
      voteChoice.length === new Set(voteChoice).size &&
      // If voteChoice is empty, return false
      voteChoice.length > 0 &&
      // If not all proposalChoices are selected, return false
      // TODO: We should add support for pacial bailout in the future
      voteChoice.length === proposalChoices.length;
    }
    getValidVotes() {
      return this.votes.filter(vote => RankedChoiceVoting.isValidChoice(vote.choice, this.proposal.choices));
    }
    getScores() {
      const finalRound = getFinalRound(this.getValidVotes());
      return this.proposal.choices.map((choice, i) => finalRound.filter(res => Number(res[0]) === i + 1).reduce((a, b) => a + b[1][0], 0));
    }
    getScoresByStrategy() {
      const finalRound = getFinalRound(this.getValidVotes());
      return this.proposal.choices.map((choice, i) => this.strategies.map((strategy, sI) => {
        return finalRound.filter(res => Number(res[0]) === i + 1).reduce((a, b) => a + b[1][1][sI], 0);
      }));
    }
    getScoresTotal() {
      return this.getScores().reduce((a, b) => a + b);
    }
    getChoiceString() {
      return this.selected.map(choice => {
        if (this.proposal.choices[choice - 1]) {
          return this.proposal.choices[choice - 1];
        }
      }).map((el, i) => `(${(0, _getNumberWithOrdinal.getNumberWithOrdinal)(i + 1)}) ${el}`).join(", ");
    }
  }
  _exports.default = RankedChoiceVoting;
});