define("discourse/plugins/karma-score/lib/get-field-values", ["exports", "discourse/plugins/karma-score/lib/is-typeof"], function (_exports, _isTypeof) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createPostTextFromFields = createPostTextFromFields;
  _exports.getFieldValues = getFieldValues;
  _exports.valuesToFields = valuesToFields;
  /**
   *
   * @param {import("karma-score").CustomField[]} fields
   * @returns
   */
  function getFieldValues(fields) {
    (0, _isTypeof.isTypeof)(fields, "array");
    return fields.map(field => ({
      label: field.label,
      value: field.value,
      displayAs: field.displayAs
    }));
  }

  /**
   *
   * @param {import("karma-score").CustomField[]} fields
   * @param {import("karma-score").FormFieldValue[]} values
   */
  function valuesToFields(fields, values) {
    (0, _isTypeof.isTypeof)(fields, "array");
    (0, _isTypeof.isTypeof)(values, "array");
    values.map(v => {
      const labelIdx = fields.findIndex(f => f.label.toLowerCase() === v.label.toLowerCase());
      if (labelIdx >= 0) {
        fields[labelIdx].value = v.value;
      }
    });
    return fields;
  }

  /**
   *
   * @param {import("karma-score").CustomField[]} fields
   */
  function createPostTextFromFields(fields) {
    let post = "";
    fields.forEach(field => {
      post += `**${field.postTitle || field.label}**: ${[field.value].flat().join(", ")}\n\n`;
    });
    return post;
  }
});