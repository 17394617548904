define("discourse/plugins/karma-score/lib/voting-history/gql/fetcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function parseBody(body) {
    return JSON.stringify(body).replace(/(\\\\)/gm, "\\").replace(/(\\t)/gm, "");
  }
  const gql = {
    async query(url, query) {
      const {
        data
      } = await fetch(url, {
        body: parseBody({
          query
        }),
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        }
      }).then(async res => await res.json());
      const response = data?.data?.data || data?.data || data;
      return {
        ...response
      };
    }
  };
  var _default = _exports.default = gql;
});