define("discourse/plugins/karma-score/lib/update-post", ["exports", "discourse/plugins/karma-score/lib/is-typeof"], function (_exports, _isTypeof) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = updatePost;
  function validate(_ref) {
    let {
      postId,
      body,
      csrf
    } = _ref;
    (0, _isTypeof.isTypeof)(body, "string");
    (0, _isTypeof.isTypeof)(csrf, "string");
    (0, _isTypeof.isTypeof)(postId, "number");
    if (!(postId && body && csrf)) {
      throw new Error("Something is missing from params.");
    }
    if (body.length < 20) {
      throw new Error("Body should have at least 20 chars.");
    }
  }

  /**
   * Post to a topic on discourse
   * @returns
   */
  function updatePost(_ref2) {
    let {
      /**
       * The post id to edit
       */
      postId,
      /**
       * The body content. Must be at least 20 char
       */
      body,
      /**
       * CSRF token provided by the session (`this.session.csrfToken`)
       */
      csrf
    } = _ref2;
    validate(...arguments);
    const reqBody = {
      raw: body,
      edit_reason: ""
    };
    return fetch(`/posts/${postId}.json`, {
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrf,
        "Content-Type": "application/json; charset=UTF-8"
      },
      body: JSON.stringify(reqBody),
      method: "PUT",
      mode: "cors"
    }).then(res => res.json()).catch(e => {
      throw e;
    });
  }
});