define("discourse/plugins/karma-score/lib/voting-history/index", ["exports", "discourse/plugins/karma-score/lib/fetch-snapshot-onchain-ids", "discourse/plugins/karma-score/lib/voting-history/gql/off-chain-fetcher", "discourse/plugins/karma-score/lib/voting-history/gql/on-chain-fetcher", "discourse/plugins/karma-score/lib/voting-history/moonbeam/moonbeam", "discourse/plugins/karma-score/lib/voting-history/template"], function (_exports, _fetchSnapshotOnchainIds, _offChainFetcher, _onChainFetcher, _moonbeam, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const karma = "https://karmahq.xyz/profile";
  const VotingHistory = {
    shouldShowVotingHistory(ctx) {
      const amount = ctx.SiteSettings.Show_voting_history?.trim?.() || 0;
      const regex = /\D/g;
      if (!regex.test(amount)) {
        return Math.min(+amount, 50);
      }
      return 0;
    },
    /**
     *
     * @param {*} profile
     * @param {*} ctx
     * @param {*} wrapperId
     * @returns {Promise<import("karma-score").ParsedProposal[])>}
     */
    async start(profile, ctx) {
      let wrapperId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ".__karma-stats";
      if (!ctx || !ctx.SiteSettings || !profile) {
        return;
      }
      if (profile && profile.address) {
        $(`${wrapperId} #__karma-voting-wrapper`).css("display", "initial");
        $(`${wrapperId} #__karma-user-profile`).prop("href", `${karma}/${profile.address}`);
      }
      const daoName = window.selectedDao;
      const amount = this.shouldShowVotingHistory(ctx);
      if (['moonbeam', 'moonriver', 'moonbase'].includes(daoName.toLowerCase())) {
        const votes = await (0, _moonbeam.moonriverFetcher)(daoName, profile.address);
        return votes.slice(0, amount);
      }

      // TODO fix this workaround by refactoring this code into components
      this.daoIds = await (0, _fetchSnapshotOnchainIds.fetchDaoSnapshotAndOnChainIds)(daoName);
      let onChain = [];
      if (this.daoIds.onChain?.length) {
        onChain = await (0, _onChainFetcher.fetchOnChainProposalVotes)([this.daoIds.onChain].flat(), profile.address, amount);
      }
      let offChain = [];
      if (this.daoIds.snapshotIds?.length) {
        offChain = await (0, _offChainFetcher.fetchOffChainProposalVotes)([this.daoIds.snapshotIds].flat(), profile.address, amount);
      }
      const votes = onChain.concat(offChain);
      votes.sort((a, b) => moment(a.executed).isBefore(moment(b.executed)) ? 1 : -1);
      return votes.slice(0, amount);
    },
    async render() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      let elId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : `.__karma-stats #__karma-voting-history`;
      const wrapper = $(`${elId}`);
      let display;
      if (data.length) {
        display = data.map(d => (0, _template.default)(d.proposal, d.voteMethod, d.executed, d.choice));
      } else {
        display = "<p>No voting history found.</p>";
      }
      wrapper.html(display);
    }
  };
  var _default = _exports.default = VotingHistory;
});