define("discourse/plugins/karma-score/discourse/templates/connectors/user-card-before-badges/karma-stats", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{karma-stats username=this.user.username}}
  
  */
  {
    "id": "HEiTLQSE",
    "block": "[[[1,[28,[35,0],null,[[\"username\"],[[30,0,[\"user\",\"username\"]]]]]],[1,\"\\n\"]],[],false,[\"karma-stats\"]]",
    "moduleName": "discourse/plugins/karma-score/discourse/templates/connectors/user-card-before-badges/karma-stats.hbs",
    "isStrictMode": false
  });
});