define("discourse/plugins/karma-score/lib/mixpanel/source.min", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MixpanelSource = void 0;
  /* eslint-disable */
  const MixpanelSource = function () {
    var k = void 0,
      l = !0,
      q = null,
      F = !1;
    (function () {
      function Aa() {
        function a() {
          if (!a.qc) la = a.qc = l, ma = F, c.a(G, function (a) {
            a.dc();
          });
        }
        function b() {
          try {
            t.documentElement.doScroll("left");
          } catch (d) {
            setTimeout(b, 1);
            return;
          }
          a();
        }
        if (t.addEventListener) "complete" === t.readyState ? a() : t.addEventListener("DOMContentLoaded", a, F);else if (t.attachEvent) {
          t.attachEvent("onreadystatechange", a);
          var d = F;
          try {
            d = A.frameElement === q;
          } catch (n) {}
          t.documentElement.doScroll && d && b();
        }
        c.Gb(A, "load", a, l);
      }
      function Ba() {
        w.init = function (a, b, d) {
          if (d) return w[d] || (w[d] = G[d] = T(a, b, d), w[d].da()), w[d];
          d = w;
          if (G.mixpanel) d = G.mixpanel;else if (a) d = T(a, b, "mixpanel"), d.da(), G.mixpanel = d;
          w = d;
          1 === ca && (A.mixpanel = w);
          Ca();
        };
      }
      function Ca() {
        c.a(G, function (a, b) {
          "mixpanel" !== b && (w[b] = a);
        });
        w._ = c;
      }
      function da(a) {
        a = c.e(a) ? a : c.g(a) ? {} : {
          days: a
        };
        return c.extend({}, Da, a);
      }
      function T(a, b, d) {
        var n,
          f = "mixpanel" === d ? w : w[d];
        if (f && 0 === ca) n = f;else {
          if (f && !c.isArray(f)) {
            p.error("You have already initialized " + d);
            return;
          }
          n = new e();
        }
        n.Ya = {};
        n.R(a, b, d);
        n.people = new i();
        n.people.R(n);
        J = J || n.c("debug");
        !c.g(f) && c.isArray(f) && (n.ua.call(n.people, f.people), n.ua(f));
        return n;
      }
      function e() {}
      function O() {}
      function Ea(a) {
        return a;
      }
      function m(a) {
        this.props = {};
        this.gb = F;
        this.name = a.persistence_name ? "mp_" + a.persistence_name : "mp_" + a.token + "_mixpanel";
        var b = a.persistence;
        if ("cookie" !== b && "localStorage" !== b) p.F("Unknown persistence type " + b + "; falling back to cookie"), b = a.persistence = "cookie";
        this.h = "localStorage" === b && c.localStorage.la() ? c.localStorage : c.cookie;
        this.load();
        this.Zb(a);
        this.kd(a);
        this.save();
      }
      function i() {}
      function s() {}
      function C(a, b) {
        this.G = b.G;
        this.W = new H(a, {
          G: c.bind(this.j, this),
          h: b.h
        });
        this.A = b.A;
        this.Nc = b.Oc;
        this.ea = b.ea;
        this.Xc = b.Yc;
        this.S = this.A.batch_size;
        this.ia = this.A.batch_flush_interval_ms;
        this.oa = !this.A.batch_autostart;
        this.Ea = 0;
      }
      function na(a, b) {
        var d = [];
        c.a(a, function (a) {
          var c = a.id;
          if (c in b) {
            if (c = b[c], c !== q) a.payload = c, d.push(a);
          } else d.push(a);
        });
        return d;
      }
      function oa(a, b) {
        var d = [];
        c.a(a, function (a) {
          a.id && !b[a.id] && d.push(a);
        });
        return d;
      }
      function H(a, b) {
        b = b || {};
        this.I = a;
        this.h = b.h || window.localStorage;
        this.j = b.G || c.bind(pa.error, pa);
        this.Oa = new qa(a, {
          h: this.h
        });
        this.ma = b.ma || q;
        this.D = [];
      }
      function qa(a, b) {
        b = b || {};
        this.I = a;
        this.h = b.h || window.localStorage;
        this.Eb = b.Eb || 100;
        this.Tb = b.Tb || 2E3;
      }
      function U() {
        this.Bb = "submit";
      }
      function L() {
        this.Bb = "click";
      }
      function D() {}
      function ra(a) {
        var b = Fa,
          d = a.split("."),
          d = d[d.length - 1];
        if (4 < d.length || "com" === d || "org" === d) b = Ga;
        return (a = a.match(b)) ? a[0] : "";
      }
      function ea(a) {
        var b = Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10);
        return a ? b.substring(0, a) : b;
      }
      function V(a, b) {
        if (fa !== q && !b) return fa;
        var d = l;
        try {
          var a = a || window.localStorage,
            c = "__mplss_" + ea(8);
          a.setItem(c, "xyz");
          "xyz" !== a.getItem(c) && (d = F);
          a.removeItem(c);
        } catch (f) {
          d = F;
        }
        return fa = d;
      }
      function ga(a) {
        return {
          log: ha(p.log, a),
          error: ha(p.error, a),
          F: ha(p.F, a)
        };
      }
      function ha(a, b) {
        return function () {
          arguments[0] = "[" + b + "] " + arguments[0];
          return a.apply(p, arguments);
        };
      }
      function Ha(a, b) {
        sa(l, a, b);
      }
      function Ia(a, b) {
        sa(F, a, b);
      }
      function Ja(a, b) {
        return "1" === W(b).get(X(a, b));
      }
      function ta(a, b) {
        if (Ka(b)) return p.warn('This browser has "Do Not Track" enabled. This will prevent the Mixpanel SDK from sending any data. To ignore the "Do Not Track" browser setting, initialize the Mixpanel instance with the config "ignore_dnt: true"'), l;
        var d = "0" === W(b).get(X(a, b));
        d && p.warn("You are opted out of Mixpanel tracking. This will prevent the Mixpanel SDK from sending any data.");
        return d;
      }
      function P(a) {
        return ia(a, function (a) {
          return this.c(a);
        });
      }
      function I(a) {
        return ia(a, function (a) {
          return this.v(a);
        });
      }
      function M(a) {
        return ia(a, function (a) {
          return this.v(a);
        });
      }
      function La(a, b) {
        b = b || {};
        W(b).remove(X(a, b), !!b.lb, b.jb);
      }
      function W(a) {
        a = a || {};
        return "localStorage" === a.Db ? c.localStorage : c.cookie;
      }
      function X(a, b) {
        b = b || {};
        return (b.Cb || Ma) + a;
      }
      function Ka(a) {
        if (a && a.sb) return F;
        var a = a && a.window || A,
          b = a.navigator || {},
          d = F;
        c.a([b.doNotTrack, b.msDoNotTrack, a.doNotTrack], function (a) {
          c.i([l, 1, "1", "yes"], a) && (d = l);
        });
        return d;
      }
      function sa(a, b, d) {
        !c.Na(b) || !b.length ? p.error("gdpr." + (a ? "optIn" : "optOut") + " called with an invalid token") : (d = d || {}, W(d).set(X(b, d), a ? 1 : 0, c.xb(d.kb) ? d.kb : q, !!d.lb, !!d.Mc, !!d.nc, d.jb), d.m && a && d.m(d.ad || "$opt_in", d.bd, {
          send_immediately: l
        }));
      }
      function ia(a, b) {
        return function () {
          var d = F;
          try {
            var c = b.call(this, "token"),
              f = b.call(this, "ignore_dnt"),
              g = b.call(this, "opt_out_tracking_persistence_type"),
              y = b.call(this, "opt_out_tracking_cookie_prefix"),
              h = b.call(this, "window");
            c && (d = ta(c, {
              sb: f,
              Db: g,
              Cb: y,
              window: h
            }));
          } catch (e) {
            p.error("Unexpected error when checking tracking opt-out status: " + e);
          }
          if (!d) return a.apply(this, arguments);
          d = arguments[arguments.length - 1];
          "function" === typeof d && d(0);
        };
      }
      var J = F,
        A;
      if ("undefined" === typeof window) {
        var B = {
          hostname: ""
        };
        A = {
          navigator: {
            userAgent: ""
          },
          document: {
            location: B,
            referrer: ""
          },
          screen: {
            width: 0,
            height: 0
          },
          location: B
        };
      } else A = window;
      var B = Array.prototype,
        ua = Object.prototype,
        K = B.slice,
        Q = ua.toString,
        Y = ua.hasOwnProperty,
        x = A.console,
        E = A.navigator,
        t = A.document,
        R = A.opera,
        Z = A.screen,
        z = E.userAgent,
        ja = Function.prototype.bind,
        va = B.forEach,
        wa = B.indexOf,
        xa = B.map,
        B = Array.isArray,
        ka = {},
        c = {
          trim: function (a) {
            return a.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
          }
        },
        p = {
          log: function () {
            if (J && !c.g(x) && x) try {
              x.log.apply(x, arguments);
            } catch (a) {
              c.a(arguments, function (a) {
                x.log(a);
              });
            }
          },
          warn: function () {
            if (J && !c.g(x) && x) {
              var a = ["Mixpanel warning:"].concat(c.J(arguments));
              try {
                x.warn.apply(x, a);
              } catch (b) {
                c.a(a, function (a) {
                  x.warn(a);
                });
              }
            }
          },
          error: function () {
            if (J && !c.g(x) && x) {
              var a = ["Mixpanel error:"].concat(c.J(arguments));
              try {
                x.error.apply(x, a);
              } catch (b) {
                c.a(a, function (a) {
                  x.error(a);
                });
              }
            }
          },
          F: function () {
            if (!c.g(x) && x) {
              var a = ["Mixpanel error:"].concat(c.J(arguments));
              try {
                x.error.apply(x, a);
              } catch (b) {
                c.a(a, function (a) {
                  x.error(a);
                });
              }
            }
          }
        };
      c.bind = function (a, b) {
        var d, n;
        if (ja && a.bind === ja) return ja.apply(a, K.call(arguments, 1));
        if (!c.Ma(a)) throw new TypeError();
        d = K.call(arguments, 2);
        return n = function () {
          if (!(this instanceof n)) return a.apply(b, d.concat(K.call(arguments)));
          var c = {};
          c.prototype = a.prototype;
          var g = new c();
          c.prototype = q;
          c = a.apply(g, d.concat(K.call(arguments)));
          return Object(c) === c ? c : g;
        };
      };
      c.a = function (a, b, d) {
        if (!(a === q || a === k)) if (va && a.forEach === va) a.forEach(b, d);else if (a.length === +a.length) for (var c = 0, f = a.length; c < f && !(c in a && b.call(d, a[c], c, a) === ka); c++);else for (c in a) if (Y.call(a, c) && b.call(d, a[c], c, a) === ka) break;
      };
      c.extend = function (a) {
        c.a(K.call(arguments, 1), function (b) {
          for (var d in b) b[d] !== k && (a[d] = b[d]);
        });
        return a;
      };
      c.isArray = B || function (a) {
        return "[object Array]" === Q.call(a);
      };
      c.Ma = function (a) {
        try {
          return /^\s*\bfunction\b/.test(a);
        } catch (b) {
          return F;
        }
      };
      c.zc = function (a) {
        return !(!a || !Y.call(a, "callee"));
      };
      c.J = function (a) {
        return !a ? [] : a.J ? a.J() : c.isArray(a) || c.zc(a) ? K.call(a) : c.md(a);
      };
      c.map = function (a, b, d) {
        if (xa && a.map === xa) return a.map(b, d);
        var n = [];
        c.a(a, function (a) {
          n.push(b.call(d, a));
        });
        return n;
      };
      c.keys = function (a) {
        var b = [];
        if (a === q) return b;
        c.a(a, function (a, c) {
          b[b.length] = c;
        });
        return b;
      };
      c.md = function (a) {
        var b = [];
        if (a === q) return b;
        c.a(a, function (a) {
          b[b.length] = a;
        });
        return b;
      };
      c.tb = function (a, b) {
        var d = F;
        if (a === q) return d;
        if (wa && a.indexOf === wa) return -1 != a.indexOf(b);
        c.a(a, function (a) {
          if (d || (d = a === b)) return ka;
        });
        return d;
      };
      c.i = function (a, b) {
        return -1 !== a.indexOf(b);
      };
      c.vb = function (a, b) {
        a.prototype = new b();
        a.Zc = b.prototype;
      };
      c.e = function (a) {
        return a === Object(a) && !c.isArray(a);
      };
      c.ka = function (a) {
        if (c.e(a)) {
          for (var b in a) if (Y.call(a, b)) return F;
          return l;
        }
        return F;
      };
      c.g = function (a) {
        return a === k;
      };
      c.Na = function (a) {
        return "[object String]" == Q.call(a);
      };
      c.Ac = function (a) {
        return "[object Date]" == Q.call(a);
      };
      c.xb = function (a) {
        return "[object Number]" == Q.call(a);
      };
      c.Bc = function (a) {
        return !!(a && 1 === a.nodeType);
      };
      c.Ga = function (a) {
        c.a(a, function (b, d) {
          c.Ac(b) ? a[d] = c.sc(b) : c.e(b) && (a[d] = c.Ga(b));
        });
        return a;
      };
      c.timestamp = function () {
        Date.now = Date.now || function () {
          return +new Date();
        };
        return Date.now();
      };
      c.sc = function (a) {
        function b(a) {
          return 10 > a ? "0" + a : a;
        }
        return a.getUTCFullYear() + "-" + b(a.getUTCMonth() + 1) + "-" + b(a.getUTCDate()) + "T" + b(a.getUTCHours()) + ":" + b(a.getUTCMinutes()) + ":" + b(a.getUTCSeconds());
      };
      c.pa = function (a) {
        var b = {};
        c.a(a, function (a, n) {
          c.Na(a) && 0 < a.length && (b[n] = a);
        });
        return b;
      };
      c.truncate = function (a, b) {
        var d;
        "string" === typeof a ? d = a.slice(0, b) : c.isArray(a) ? (d = [], c.a(a, function (a) {
          d.push(c.truncate(a, b));
        })) : c.e(a) ? (d = {}, c.a(a, function (a, f) {
          d[f] = c.truncate(a, b);
        })) : d = a;
        return d;
      };
      c.aa = function () {
        return function (a) {
          function b(a, c) {
            var g = "",
              y = 0,
              h = y = "",
              h = 0,
              e = g,
              o = [],
              r = c[a];
            r && "object" === typeof r && "function" === typeof r.toJSON && (r = r.toJSON(a));
            switch (typeof r) {
              case "string":
                return d(r);
              case "number":
                return isFinite(r) ? "" + r : "null";
              case "boolean":
              case "null":
                return "" + r;
              case "object":
                if (!r) return "null";
                g += "    ";
                o = [];
                if ("[object Array]" === Q.apply(r)) {
                  h = r.length;
                  for (y = 0; y < h; y += 1) o[y] = b(y, r) || "null";
                  return h = 0 === o.length ? "[]" : g ? "[\n" + g + o.join(",\n" + g) + "\n" + e + "]" : "[" + o.join(",") + "]";
                }
                for (y in r) Y.call(r, y) && (h = b(y, r)) && o.push(d(y) + (g ? ": " : ":") + h);
                return h = 0 === o.length ? "{}" : g ? "{" + o.join(",") + "" + e + "}" : "{" + o.join(",") + "}";
            }
          }
          function d(a) {
            var b = /[\\"\x00-\x1f\x7f-\x9f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
              d = {
                "\u0008": "\\b",
                "\t": "\\t",
                "\n": "\\n",
                "\u000c": "\\f",
                "\r": "\\r",
                '"': '\\"',
                "\\": "\\\\"
              };
            b.lastIndex = 0;
            return b.test(a) ? '"' + a.replace(b, function (a) {
              var b = d[a];
              return "string" === typeof b ? b : "\\u" + ("0000" + a.charCodeAt(0).toString(16)).slice(-4);
            }) + '"' : '"' + a + '"';
          }
          return b("", {
            "": a
          });
        };
      }();
      c.M = function () {
        function a() {
          switch (h) {
            case "t":
              return f("t"), f("r"), f("u"), f("e"), l;
            case "f":
              return f("f"), f("a"), f("l"), f("s"), f("e"), F;
            case "n":
              return f("n"), f("u"), f("l"), f("l"), q;
          }
          g('Unexpected "' + h + '"');
        }
        function b() {
          for (; h && " " >= h;) f();
        }
        function d() {
          var a,
            b,
            d = "",
            c;
          if ('"' === h) for (; f();) {
            if ('"' === h) return f(), d;
            if ("\\" === h) {
              if (f(), "u" === h) {
                for (b = c = 0; 4 > b; b += 1) {
                  a = parseInt(f(), 16);
                  if (!isFinite(a)) break;
                  c = 16 * c + a;
                }
                d += String.fromCharCode(c);
              } else if ("string" === typeof j[h]) d += j[h];else break;
            } else d += h;
          }
          g("Bad string");
        }
        function c() {
          var a;
          a = "";
          "-" === h && (a = "-", f("-"));
          for (; "0" <= h && "9" >= h;) a += h, f();
          if ("." === h) for (a += "."; f() && "0" <= h && "9" >= h;) a += h;
          if ("e" === h || "E" === h) {
            a += h;
            f();
            if ("-" === h || "+" === h) a += h, f();
            for (; "0" <= h && "9" >= h;) a += h, f();
          }
          a = +a;
          if (isFinite(a)) return a;
          g("Bad number");
        }
        function f(a) {
          a && a !== h && g("Expected '" + a + "' instead of '" + h + "'");
          h = o.charAt(e);
          e += 1;
          return h;
        }
        function g(a) {
          a = new SyntaxError(a);
          a.nd = e;
          a.text = o;
          throw a;
        }
        var e,
          h,
          j = {
            '"': '"',
            "\\": "\\",
            "/": "/",
            b: "\u0008",
            f: "\u000c",
            n: "\n",
            r: "\r",
            t: "\t"
          },
          o,
          r;
        r = function () {
          b();
          switch (h) {
            case "{":
              var e;
              a: {
                var y,
                  j = {};
                if ("{" === h) {
                  f("{");
                  b();
                  if ("}" === h) {
                    f("}");
                    e = j;
                    break a;
                  }
                  for (; h;) {
                    y = d();
                    b();
                    f(":");
                    Object.hasOwnProperty.call(j, y) && g('Duplicate key "' + y + '"');
                    j[y] = r();
                    b();
                    if ("}" === h) {
                      f("}");
                      e = j;
                      break a;
                    }
                    f(",");
                    b();
                  }
                }
                g("Bad object");
              }
              return e;
            case "[":
              a: {
                e = [];
                if ("[" === h) {
                  f("[");
                  b();
                  if ("]" === h) {
                    f("]");
                    y = e;
                    break a;
                  }
                  for (; h;) {
                    e.push(r());
                    b();
                    if ("]" === h) {
                      f("]");
                      y = e;
                      break a;
                    }
                    f(",");
                    b();
                  }
                }
                g("Bad array");
              }
              return y;
            case '"':
              return d();
            case "-":
              return c();
            default:
              return "0" <= h && "9" >= h ? c() : a();
          }
        };
        return function (a) {
          o = a;
          e = 0;
          h = " ";
          a = r();
          b();
          h && g("Syntax error");
          return a;
        };
      }();
      c.kc = function (a) {
        var b,
          d,
          n,
          f,
          g = 0,
          e = 0,
          h = "",
          h = [];
        if (!a) return a;
        a = c.ld(a);
        do b = a.charCodeAt(g++), d = a.charCodeAt(g++), n = a.charCodeAt(g++), f = b << 16 | d << 8 | n, b = f >> 18 & 63, d = f >> 12 & 63, n = f >> 6 & 63, f &= 63, h[e++] = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".charAt(b) + "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".charAt(d) + "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".charAt(n) + "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".charAt(f); while (g < a.length);
        h = h.join("");
        switch (a.length % 3) {
          case 1:
            h = h.slice(0, -2) + "==";
            break;
          case 2:
            h = h.slice(0, -1) + "=";
        }
        return h;
      };
      c.ld = function (a) {
        var a = (a + "").replace(/\r\n/g, "\n").replace(/\r/g, "\n"),
          b = "",
          d,
          c,
          f = 0,
          g;
        d = c = 0;
        f = a.length;
        for (g = 0; g < f; g++) {
          var e = a.charCodeAt(g),
            h = q;
          128 > e ? c++ : h = 127 < e && 2048 > e ? String.fromCharCode(e >> 6 | 192, e & 63 | 128) : String.fromCharCode(e >> 12 | 224, e >> 6 & 63 | 128, e & 63 | 128);
          h !== q && (c > d && (b += a.substring(d, c)), b += h, d = c = g + 1);
        }
        c > d && (b += a.substring(d, a.length));
        return b;
      };
      c.Xa = function () {
        function a() {
          function a(b, d) {
            var c,
              f = 0;
            for (c = 0; c < d.length; c++) f |= g[c] << 8 * c;
            return b ^ f;
          }
          var b,
            c,
            g = [],
            e = 0;
          for (b = 0; b < z.length; b++) c = z.charCodeAt(b), g.unshift(c & 255), 4 <= g.length && (e = a(e, g), g = []);
          0 < g.length && (e = a(e, g));
          return e.toString(16);
        }
        function b() {
          for (var a = 1 * new Date(), b = 0; a == 1 * new Date();) b++;
          return a.toString(16) + b.toString(16);
        }
        return function () {
          var d = (Z.height * Z.width).toString(16);
          return b() + "-" + Math.random().toString(16).replace(".", "") + "-" + a() + "-" + d + "-" + b();
        };
      }();
      var ya = "ahrefsbot,baiduspider,bingbot,bingpreview,facebookexternal,petalbot,pinterest,screaming frog,yahoo! slurp,yandexbot,adsbot-google,apis-google,duplexweb-google,feedfetcher-google,google favicon,google web preview,google-read-aloud,googlebot,googleweblight,mediapartners-google,storebot-google".split(",");
      c.wb = function (a) {
        var b,
          a = a.toLowerCase();
        for (b = 0; b < ya.length; b++) if (-1 !== a.indexOf(ya[b])) return l;
        return F;
      };
      c.bc = function (a) {
        var b,
          d,
          n,
          f = [];
        c.g(b) && (b = "&");
        c.a(a, function (a, b) {
          d = encodeURIComponent(a.toString());
          n = encodeURIComponent(b);
          f[f.length] = n + "=" + d;
        });
        return f.join(b);
      };
      c.qb = function (a, b) {
        var b = b.replace(/[[]/, "\\[").replace(/[\]]/, "\\]"),
          d = RegExp("[\\?&]" + b + "=([^&#]*)").exec(a);
        if (d === q || d && "string" !== typeof d[1] && d[1].length) return "";
        d = d[1];
        try {
          d = decodeURIComponent(d);
        } catch (c) {
          p.error("Skipping decoding for malformed query param: " + d);
        }
        return d.replace(/\+/g, " ");
      };
      c.cookie = {
        get: function (a) {
          for (var a = a + "=", b = t.cookie.split(";"), d = 0; d < b.length; d++) {
            for (var c = b[d]; " " == c.charAt(0);) c = c.substring(1, c.length);
            if (0 === c.indexOf(a)) return decodeURIComponent(c.substring(a.length, c.length));
          }
          return q;
        },
        parse: function (a) {
          var b;
          try {
            b = c.M(c.cookie.get(a)) || {};
          } catch (d) {}
          return b;
        },
        qd: function (a, b, c, n, f, g, e) {
          var h = "",
            j = "",
            o = "";
          e ? h = "; domain=" + e : n && (h = (h = ra(t.location.hostname)) ? "; domain=." + h : "");
          c && (j = new Date(), j.setTime(j.getTime() + 1E3 * c), j = "; expires=" + j.toGMTString());
          g && (f = l, o = "; SameSite=None");
          f && (o += "; secure");
          t.cookie = a + "=" + encodeURIComponent(b) + j + "; path=/" + h + o;
        },
        set: function (a, b, c, n, f, g, e) {
          var h = "",
            j = "",
            o = "";
          e ? h = "; domain=" + e : n && (h = (h = ra(t.location.hostname)) ? "; domain=." + h : "");
          c && (j = new Date(), j.setTime(j.getTime() + 864E5 * c), j = "; expires=" + j.toGMTString());
          g && (f = l, o = "; SameSite=None");
          f && (o += "; secure");
          a = a + "=" + encodeURIComponent(b) + j + "; path=/" + h + o;
          return t.cookie = a;
        },
        remove: function (a, b, d) {
          c.cookie.set(a, "", -1, b, F, F, d);
        }
      };
      var fa = q;
      c.localStorage = {
        la: function (a) {
          (a = V(q, a)) || p.error("localStorage unsupported; falling back to cookie store");
          return a;
        },
        error: function (a) {
          p.error("localStorage error: " + a);
        },
        get: function (a) {
          try {
            return window.localStorage.getItem(a);
          } catch (b) {
            c.localStorage.error(b);
          }
          return q;
        },
        parse: function (a) {
          try {
            return c.M(c.localStorage.get(a)) || {};
          } catch (b) {}
          return q;
        },
        set: function (a, b) {
          try {
            window.localStorage.setItem(a, b);
          } catch (d) {
            c.localStorage.error(d);
          }
        },
        remove: function (a) {
          try {
            window.localStorage.removeItem(a);
          } catch (b) {
            c.localStorage.error(b);
          }
        }
      };
      c.Gb = function () {
        function a(a, n, f) {
          return function (g) {
            if (g = g || b(window.event)) {
              var e = l,
                h;
              c.Ma(f) && (h = f(g));
              g = n.call(a, g);
              if (F === h || F === g) e = F;
              return e;
            }
          };
        }
        function b(a) {
          if (a) a.preventDefault = b.preventDefault, a.stopPropagation = b.stopPropagation;
          return a;
        }
        b.preventDefault = function () {
          this.returnValue = F;
        };
        b.stopPropagation = function () {
          this.cancelBubble = l;
        };
        return function (b, c, f, g, e) {
          b ? b.addEventListener && !g ? b.addEventListener(c, f, !!e) : (c = "on" + c, b[c] = a(b, f, b[c])) : p.error("No valid element provided to register_event");
        };
      }();
      var Na = /^(\w*)\[(\w+)([=~\|\^\$\*]?)=?"?([^\]"]*)"?\]$/;
      c.pc = function () {
        function a(a, b) {
          return 0 <= (" " + a.className + " ").replace(d, " ").indexOf(" " + b + " ");
        }
        function b(b) {
          if (!t.getElementsByTagName) return [];
          var b = b.split(" "),
            d,
            g,
            e,
            h,
            j,
            o,
            r,
            v = [t];
          for (h = 0; h < b.length; h++) if (d = b[h].replace(/^\s+/, "").replace(/\s+$/, ""), -1 < d.indexOf("#")) {
            g = d.split("#");
            d = g[0];
            v = t.getElementById(g[1]);
            if (!v || d && v.nodeName.toLowerCase() != d) return [];
            v = [v];
          } else if (-1 < d.indexOf(".")) {
            g = d.split(".");
            d = g[0];
            var u = g[1];
            d || (d = "*");
            g = [];
            for (j = e = 0; j < v.length; j++) {
              r = "*" == d ? v[j].all ? v[j].all : v[j].getElementsByTagName("*") : v[j].getElementsByTagName(d);
              for (o = 0; o < r.length; o++) g[e++] = r[o];
            }
            v = [];
            for (j = d = 0; j < g.length; j++) g[j].className && c.Na(g[j].className) && a(g[j], u) && (v[d++] = g[j]);
          } else if (g = d.match(Na)) {
            d = g[1];
            var i = g[2],
              u = g[3],
              m = g[4];
            d || (d = "*");
            g = [];
            for (j = e = 0; j < v.length; j++) {
              r = "*" == d ? v[j].all ? v[j].all : v[j].getElementsByTagName("*") : v[j].getElementsByTagName(d);
              for (o = 0; o < r.length; o++) g[e++] = r[o];
            }
            v = [];
            d = 0;
            switch (u) {
              case "=":
                u = function (a) {
                  return a.getAttribute(i) == m;
                };
                break;
              case "~":
                u = function (a) {
                  return a.getAttribute(i).match(RegExp("\\b" + m + "\\b"));
                };
                break;
              case "|":
                u = function (a) {
                  return a.getAttribute(i).match(RegExp("^" + m + "-?"));
                };
                break;
              case "^":
                u = function (a) {
                  return 0 === a.getAttribute(i).indexOf(m);
                };
                break;
              case "$":
                u = function (a) {
                  return a.getAttribute(i).lastIndexOf(m) == a.getAttribute(i).length - m.length;
                };
                break;
              case "*":
                u = function (a) {
                  return -1 < a.getAttribute(i).indexOf(m);
                };
                break;
              default:
                u = function (a) {
                  return a.getAttribute(i);
                };
            }
            v = [];
            for (j = d = 0; j < g.length; j++) u(g[j]) && (v[d++] = g[j]);
          } else {
            g = [];
            for (j = e = 0; j < v.length; j++) {
              r = v[j].getElementsByTagName(d);
              for (o = 0; o < r.length; o++) g[e++] = r[o];
            }
            v = g;
          }
          return v;
        }
        var d = /[\t\r\n]/g;
        return function (a) {
          return c.Bc(a) ? [a] : c.e(a) && !c.g(a.length) ? a : b.call(this, a);
        };
      }();
      c.info = {
        mc: function () {
          var a = "",
            b = {};
          c.a("utm_source utm_medium utm_campaign utm_content utm_term".split(" "), function (d) {
            a = c.qb(t.URL, d);
            a.length && (b[d] = a);
          });
          return b;
        },
        Kc: function (a) {
          return 0 === a.search("https?://(.*)google.([^/?]*)") ? "google" : 0 === a.search("https?://(.*)bing.com") ? "bing" : 0 === a.search("https?://(.*)yahoo.com") ? "yahoo" : 0 === a.search("https?://(.*)duckduckgo.com") ? "duckduckgo" : q;
        },
        Lc: function (a) {
          var b = c.info.Kc(a),
            d = {};
          if (b !== q) d.$search_engine = b, a = c.qb(a, "yahoo" != b ? "q" : "p"), a.length && (d.mp_keyword = a);
          return d;
        },
        T: function (a, b, d) {
          return d || c.i(a, " OPR/") ? c.i(a, "Mini") ? "Opera Mini" : "Opera" : /(BlackBerry|PlayBook|BB10)/i.test(a) ? "BlackBerry" : c.i(a, "IEMobile") || c.i(a, "WPDesktop") ? "Internet Explorer Mobile" : c.i(a, "SamsungBrowser/") ? "Samsung Internet" : c.i(a, "Edge") || c.i(a, "Edg/") ? "Microsoft Edge" : c.i(a, "FBIOS") ? "Facebook Mobile" : c.i(a, "Chrome") ? "Chrome" : c.i(a, "CriOS") ? "Chrome iOS" : c.i(a, "UCWEB") || c.i(a, "UCBrowser") ? "UC Browser" : c.i(a, "FxiOS") ? "Firefox iOS" : c.i(b || "", "Apple") ? c.i(a, "Mobile") ? "Mobile Safari" : "Safari" : c.i(a, "Android") ? "Android Mobile" : c.i(a, "Konqueror") ? "Konqueror" : c.i(a, "Firefox") ? "Firefox" : c.i(a, "MSIE") || c.i(a, "Trident/") ? "Internet Explorer" : c.i(a, "Gecko") ? "Mozilla" : "";
        },
        Da: function (a, b, d) {
          b = {
            "Internet Explorer Mobile": /rv:(\d+(\.\d+)?)/,
            "Microsoft Edge": /Edge?\/(\d+(\.\d+)?)/,
            Chrome: /Chrome\/(\d+(\.\d+)?)/,
            "Chrome iOS": /CriOS\/(\d+(\.\d+)?)/,
            "UC Browser": /(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/,
            Safari: /Version\/(\d+(\.\d+)?)/,
            "Mobile Safari": /Version\/(\d+(\.\d+)?)/,
            Opera: /(Opera|OPR)\/(\d+(\.\d+)?)/,
            Firefox: /Firefox\/(\d+(\.\d+)?)/,
            "Firefox iOS": /FxiOS\/(\d+(\.\d+)?)/,
            Konqueror: /Konqueror:(\d+(\.\d+)?)/,
            BlackBerry: /BlackBerry (\d+(\.\d+)?)/,
            "Android Mobile": /android\s(\d+(\.\d+)?)/,
            "Samsung Internet": /SamsungBrowser\/(\d+(\.\d+)?)/,
            "Internet Explorer": /(rv:|MSIE )(\d+(\.\d+)?)/,
            Mozilla: /rv:(\d+(\.\d+)?)/
          }[c.info.T(a, b, d)];
          if (b === k) return q;
          a = a.match(b);
          return !a ? q : parseFloat(a[a.length - 2]);
        },
        Qa: function () {
          return /Windows/i.test(z) ? /Phone/.test(z) || /WPDesktop/.test(z) ? "Windows Phone" : "Windows" : /(iPhone|iPad|iPod)/.test(z) ? "iOS" : /Android/.test(z) ? "Android" : /(BlackBerry|PlayBook|BB10)/i.test(z) ? "BlackBerry" : /Mac/i.test(z) ? "Mac OS X" : /Linux/.test(z) ? "Linux" : /CrOS/.test(z) ? "Chrome OS" : "";
        },
        pb: function (a) {
          return /Windows Phone/i.test(a) || /WPDesktop/.test(a) ? "Windows Phone" : /iPad/.test(a) ? "iPad" : /iPod/.test(a) ? "iPod Touch" : /iPhone/.test(a) ? "iPhone" : /(BlackBerry|PlayBook|BB10)/i.test(a) ? "BlackBerry" : /Android/.test(a) ? "Android" : "";
        },
        Fb: function (a) {
          a = a.split("/");
          return 3 <= a.length ? a[2] : "";
        },
        V: function () {
          return c.extend(c.pa({
            $os: c.info.Qa(),
            $browser: c.info.T(z, E.vendor, R),
            $referrer: t.referrer,
            $referring_domain: c.info.Fb(t.referrer),
            $device: c.info.pb(z)
          }), {
            $current_url: A.location.href,
            $browser_version: c.info.Da(z, E.vendor, R),
            $screen_height: Z.height,
            $screen_width: Z.width,
            mp_lib: "web",
            $lib_version: "2.45.0",
            $insert_id: ea(),
            time: c.timestamp() / 1E3
          });
        },
        Gc: function () {
          return c.extend(c.pa({
            $os: c.info.Qa(),
            $browser: c.info.T(z, E.vendor, R)
          }), {
            $browser_version: c.info.Da(z, E.vendor, R)
          });
        },
        Ec: function (a) {
          return c.pa({
            mp_page: a,
            mp_referrer: t.referrer,
            mp_browser: c.info.T(z, E.vendor, R),
            mp_platform: c.info.Qa()
          });
        }
      };
      var Ga = /[a-z0-9][a-z0-9-]*\.[a-z]+$/i,
        Fa = /[a-z0-9][a-z0-9-]+\.[a-z.]{2,6}$/i,
        $ = q,
        aa = q;
      if ("undefined" !== typeof JSON) $ = JSON.stringify, aa = JSON.parse;
      $ = $ || c.aa;
      aa = aa || c.M;
      c.toArray = c.J;
      c.isObject = c.e;
      c.JSONEncode = c.aa;
      c.JSONDecode = c.M;
      c.isBlockedUA = c.wb;
      c.isEmptyObject = c.ka;
      c.info = c.info;
      c.info.device = c.info.pb;
      c.info.browser = c.info.T;
      c.info.browserVersion = c.info.Da;
      c.info.properties = c.info.V;
      D.prototype.ga = function () {};
      D.prototype.Ia = function () {};
      D.prototype.Ba = function () {};
      D.prototype.La = function (a) {
        this.yb = a;
        return this;
      };
      D.prototype.m = function (a, b, d, n) {
        var f = this,
          g = c.pc(a);
        if (0 === g.length) p.error("The DOM query (" + a + ") returned 0 elements");else return c.a(g, function (a) {
          c.Gb(a, this.Bb, function (a) {
            var c = {},
              g = f.ga(d, this),
              e = f.yb.c("track_links_timeout");
            f.Ia(a, this, c);
            window.setTimeout(f.Vb(n, g, c, l), e);
            f.yb.m(b, g, f.Vb(n, g, c));
          });
        }, this), l;
      };
      D.prototype.Vb = function (a, b, c, n) {
        var n = n || F,
          f = this;
        return function () {
          if (!c.lc) c.lc = l, a && a(n, b) === F || f.Ba(b, c, n);
        };
      };
      D.prototype.ga = function (a, b) {
        return "function" === typeof a ? a(b) : c.extend({}, a);
      };
      c.vb(L, D);
      L.prototype.ga = function (a, b) {
        var c = L.Zc.ga.apply(this, arguments);
        if (b.href) c.url = b.href;
        return c;
      };
      L.prototype.Ia = function (a, b, c) {
        c.zb = 2 === a.which || a.metaKey || a.ctrlKey || "_blank" === b.target;
        c.href = b.href;
        c.zb || a.preventDefault();
      };
      L.prototype.Ba = function (a, b) {
        b.zb || setTimeout(function () {
          window.location = b.href;
        }, 0);
      };
      c.vb(U, D);
      U.prototype.Ia = function (a, b, c) {
        c.element = b;
        a.preventDefault();
      };
      U.prototype.Ba = function (a, b) {
        setTimeout(function () {
          b.element.submit();
        }, 0);
      };
      var Oa = ga("lock");
      qa.prototype.Wa = function (a, b, c) {
        function n() {
          i.setItem(t, "1");
          try {
            a();
          } finally {
            i.removeItem(t), i.getItem(p) === j && i.removeItem(p), i.getItem(m) === j && i.removeItem(m);
          }
        }
        function f() {
          i.setItem(m, j);
          e(g, function () {
            i.getItem(m) === j ? n() : h(function () {
              i.getItem(p) !== j ? f() : e(function () {
                return !i.getItem(t);
              }, n);
            });
          });
        }
        function g() {
          var a = i.getItem(p);
          if (a && a !== j) return F;
          i.setItem(p, j);
          if (i.getItem(p) === j) return l;
          if (!V(i, l)) throw Error("localStorage support dropped while acquiring lock");
          return F;
        }
        function e(a, b) {
          a() ? b() : h(function () {
            e(a, b);
          });
        }
        function h(a) {
          new Date().getTime() - o > u ? (Oa.error("Timeout waiting for mutex on " + r + "; clearing lock. [" + j + "]"), i.removeItem(t), i.removeItem(p), f()) : setTimeout(function () {
            try {
              a();
            } catch (c) {
              b && b(c);
            }
          }, v * (Math.random() + 0.1));
        }
        !c && "function" !== typeof b && (c = b, b = q);
        var j = c || new Date().getTime() + "|" + Math.random(),
          o = new Date().getTime(),
          r = this.I,
          v = this.Eb,
          u = this.Tb,
          i = this.h,
          m = r + ":X",
          p = r + ":Y",
          t = r + ":Z";
        try {
          if (V(i, l)) f();else throw Error("localStorage support check failed");
        } catch (s) {
          b && b(s);
        }
      };
      var pa = ga("batch");
      H.prototype.Ha = function (a, b, d) {
        var n = {
          id: ea(),
          flushAfter: new Date().getTime() + 2 * b,
          payload: a
        };
        this.Oa.Wa(c.bind(function () {
          var b;
          try {
            var c = this.X();
            c.push(n);
            (b = this.Sa(c)) && this.D.push(n);
          } catch (e) {
            this.j("Error enqueueing item", a), b = F;
          }
          d && d(b);
        }, this), c.bind(function (a) {
          this.j("Error acquiring storage lock", a);
          d && d(F);
        }, this), this.ma);
      };
      H.prototype.rc = function (a) {
        var b = this.D.slice(0, a);
        if (b.length < a) {
          var d = this.X();
          if (d.length) {
            var n = {};
            c.a(b, function (a) {
              n[a.id] = l;
            });
            for (var f = 0; f < d.length; f++) {
              var g = d[f];
              if (new Date().getTime() > g.flushAfter && !n[g.id] && (g.Dc = l, b.push(g), b.length >= a)) break;
            }
          }
        }
        return b;
      };
      H.prototype.Hc = function (a, b) {
        var d = {};
        c.a(a, function (a) {
          d[a] = l;
        });
        this.D = oa(this.D, d);
        var n = c.bind(function () {
          var b;
          try {
            var c = this.X(),
              c = oa(c, d);
            if (b = this.Sa(c)) for (var c = this.X(), n = 0; n < c.length; n++) {
              var e = c[n];
              if (e.id && d[e.id]) return this.j("Item not removed from storage"), F;
            }
          } catch (j) {
            this.j("Error removing items", a), b = F;
          }
          return b;
        }, this);
        this.Oa.Wa(function () {
          var a = n();
          b && b(a);
        }, c.bind(function (a) {
          var c = F;
          this.j("Error acquiring storage lock", a);
          if (!V(this.h, l) && (c = n(), !c)) try {
            this.h.removeItem(this.I);
          } catch (d) {
            this.j("Error clearing queue", d);
          }
          b && b(c);
        }, this), this.ma);
      };
      H.prototype.hd = function (a) {
        this.D = na(this.D, a);
        this.Oa.Wa(c.bind(function () {
          try {
            var b = this.X(),
              b = na(b, a);
            this.Sa(b);
          } catch (c) {
            this.j("Error updating items", a);
          }
        }, this), c.bind(function (a) {
          this.j("Error acquiring storage lock", a);
        }, this), this.ma);
      };
      H.prototype.X = function () {
        var a;
        try {
          if (a = this.h.getItem(this.I)) a = aa(a), c.isArray(a) || (this.j("Invalid storage entry:", a), a = q);
        } catch (b) {
          this.j("Error retrieving queue", b), a = q;
        }
        return a || [];
      };
      H.prototype.Sa = function (a) {
        try {
          return this.h.setItem(this.I, $(a)), l;
        } catch (b) {
          return this.j("Error saving queue", b), F;
        }
      };
      H.prototype.clear = function () {
        this.D = [];
        this.h.removeItem(this.I);
      };
      var S = ga("batch");
      C.prototype.Ha = function (a, b) {
        this.W.Ha(a, this.ia, b);
      };
      C.prototype.start = function () {
        this.oa = F;
        this.Ea = 0;
        this.flush();
      };
      C.prototype.stop = function () {
        this.oa = l;
        if (this.Ta) clearTimeout(this.Ta), this.Ta = q;
      };
      C.prototype.clear = function () {
        this.W.clear();
      };
      C.prototype.Jb = function () {
        this.S = this.A.batch_size;
      };
      C.prototype.Y = function () {
        this.Kb(this.A.batch_flush_interval_ms);
      };
      C.prototype.Kb = function (a) {
        this.ia = a;
        if (!this.oa) this.Ta = setTimeout(c.bind(this.flush, this), this.ia);
      };
      C.prototype.flush = function (a) {
        try {
          if (this.Ib) S.log("Flush: Request already in progress");else {
            var a = a || {},
              b = this.A.batch_request_timeout_ms,
              d = new Date().getTime(),
              n = this.S,
              f = this.W.rc(n),
              e = [],
              i = {};
            c.a(f, function (a) {
              var b = a.payload;
              this.ea && !a.Dc && (b = this.ea(b));
              b && e.push(b);
              i[a.id] = b;
            }, this);
            if (1 > e.length) this.Y();else {
              this.Ib = l;
              var h = c.bind(function (e) {
                  this.Ib = F;
                  try {
                    var g = F;
                    if (a.Xb) this.W.hd(i);else if (c.e(e) && "timeout" === e.error && new Date().getTime() - d >= b) this.j("Network timeout; retrying"), this.flush();else if (c.e(e) && e.L && (500 <= e.L.status || 429 === e.L.status || "timeout" === e.error)) {
                      var h = 2 * this.ia,
                        j = e.L.responseHeaders;
                      if (j) {
                        var o = j["Retry-After"];
                        o && (h = 1E3 * parseInt(o, 10) || h);
                      }
                      h = Math.min(6E5, h);
                      this.j("Error; retry in " + h + " ms");
                      this.Kb(h);
                    } else if (c.e(e) && e.L && 413 === e.L.status) {
                      if (1 < f.length) {
                        var m = Math.max(1, Math.floor(n / 2));
                        this.S = Math.min(this.S, m, f.length - 1);
                        this.j("413 response; reducing batch size to " + this.S);
                        this.Y();
                      } else this.j("Single-event request too large; dropping", f), this.Jb(), g = l;
                    } else g = l;
                    g && this.W.Hc(c.map(f, function (a) {
                      return a.id;
                    }), c.bind(function (a) {
                      a ? (this.Ea = 0, this.flush()) : (this.j("Failed to remove items from queue"), 5 < ++this.Ea ? (this.j("Too many queue failures; disabling batching system."), this.Xc()) : this.Y());
                    }, this));
                  } catch (p) {
                    this.j("Error handling API response", p), this.Y();
                  }
                }, this),
                j = {
                  method: "POST",
                  ac: l,
                  xc: l,
                  Ub: b
                };
              if (a.Xb) j.Ua = "sendBeacon";
              S.log("MIXPANEL REQUEST:", e);
              this.Nc(e, j, h);
            }
          }
        } catch (o) {
          this.j("Error flushing request queue", o), this.Y();
        }
      };
      C.prototype.j = function (a, b) {
        S.error.apply(S.error, arguments);
        if (this.G) try {
          b instanceof Error || (b = Error(a)), this.G(a, b);
        } catch (c) {
          S.error(c);
        }
      };
      var Ma = "__mp_opt_in_out_",
        B = {
          Mb: function (a, b) {
            var d = {},
              e = {};
            c.e(a) ? c.a(a, function (a, b) {
              this.z(b) || (e[b] = a);
            }, this) : e[a] = b;
            d.$set = e;
            return d;
          },
          Yb: function (a) {
            var b = {},
              d = [];
            c.isArray(a) || (a = [a]);
            c.a(a, function (a) {
              this.z(a) || d.push(a);
            }, this);
            b.$unset = d;
            return b;
          },
          Pb: function (a, b) {
            var d = {},
              e = {};
            c.e(a) ? c.a(a, function (a, b) {
              this.z(b) || (e[b] = a);
            }, this) : e[a] = b;
            d.$set_once = e;
            return d;
          },
          Wb: function (a, b) {
            var d = {},
              e = {};
            c.e(a) ? c.a(a, function (a, b) {
              this.z(b) || (e[b] = c.isArray(a) ? a : [a]);
            }, this) : e[a] = c.isArray(b) ? b : [b];
            d.$union = e;
            return d;
          },
          jc: function (a, b) {
            var d = {},
              e = {};
            c.e(a) ? c.a(a, function (a, b) {
              this.z(b) || (e[b] = a);
            }, this) : e[a] = b;
            d.$append = e;
            return d;
          },
          Hb: function (a, b) {
            var d = {},
              e = {};
            c.e(a) ? c.a(a, function (a, b) {
              this.z(b) || (e[b] = a);
            }, this) : e[a] = b;
            d.$remove = e;
            return d;
          },
          od: function () {
            return {
              $delete: ""
            };
          }
        };
      c.extend(s.prototype, B);
      s.prototype.R = function (a, b, c) {
        this.d = a;
        this.xa = b;
        this.wa = c;
      };
      s.prototype.set = M(function (a, b, d) {
        var e = this.Mb(a, b);
        c.e(a) && (d = b);
        return this.k(e, d);
      });
      s.prototype.na = M(function (a, b, d) {
        var e = this.Pb(a, b);
        c.e(a) && (d = b);
        return this.k(e, d);
      });
      s.prototype.qa = M(function (a, b) {
        return this.k(this.Yb(a), b);
      });
      s.prototype.Z = M(function (a, b, d) {
        c.e(a) && (d = b);
        return this.k(this.Wb(a, b), d);
      });
      s.prototype["delete"] = M(function (a) {
        return this.k({
          $delete: ""
        }, a);
      });
      s.prototype.remove = M(function (a, b, c) {
        return this.k(this.Hb(a, b), c);
      });
      s.prototype.k = function (a, b) {
        a.$group_key = this.xa;
        a.$group_id = this.wa;
        a.$token = this.v("token");
        return this.d.Aa({
          type: "groups",
          data: c.Ga(a),
          C: this.v("api_host") + "/groups/",
          Ca: this.d.p.wc
        }, b);
      };
      s.prototype.z = function (a) {
        return "$group_key" === a || "$group_id" === a;
      };
      s.prototype.v = function (a) {
        return this.d.c(a);
      };
      s.prototype.toString = function () {
        return this.d.toString() + ".group." + this.xa + "." + this.wa;
      };
      s.prototype.remove = s.prototype.remove;
      s.prototype.set = s.prototype.set;
      s.prototype.set_once = s.prototype.na;
      s.prototype.union = s.prototype.Z;
      s.prototype.unset = s.prototype.qa;
      s.prototype.toString = s.prototype.toString;
      c.extend(i.prototype, B);
      i.prototype.R = function (a) {
        this.d = a;
      };
      i.prototype.set = I(function (a, b, d) {
        var e = this.Mb(a, b);
        c.e(a) && (d = b);
        this.v("save_referrer") && this.d.persistence.Va(document.referrer);
        e.$set = c.extend({}, c.info.Gc(), this.d.persistence.vc(), e.$set);
        return this.k(e, d);
      });
      i.prototype.na = I(function (a, b, d) {
        var e = this.Pb(a, b);
        c.e(a) && (d = b);
        return this.k(e, d);
      });
      i.prototype.qa = I(function (a, b) {
        return this.k(this.Yb(a), b);
      });
      i.prototype.ub = I(function (a, b, d) {
        var e = {},
          f = {};
        c.e(a) ? (c.a(a, function (a, b) {
          this.z(b) || (isNaN(parseFloat(a)) ? p.error("Invalid increment value passed to mixpanel.people.increment - must be a number") : f[b] = a);
        }, this), d = b) : (c.g(b) && (b = 1), f[a] = b);
        e.$add = f;
        return this.k(e, d);
      });
      i.prototype.append = I(function (a, b, d) {
        c.e(a) && (d = b);
        return this.k(this.jc(a, b), d);
      });
      i.prototype.remove = I(function (a, b, d) {
        c.e(a) && (d = b);
        return this.k(this.Hb(a, b), d);
      });
      i.prototype.Z = I(function (a, b, d) {
        c.e(a) && (d = b);
        return this.k(this.Wb(a, b), d);
      });
      i.prototype.cd = I(function (a, b, d) {
        if (!c.xb(a) && (a = parseFloat(a), isNaN(a))) {
          p.error("Invalid value passed to mixpanel.people.track_charge - must be a number");
          return;
        }
        return this.append("$transactions", c.extend({
          $amount: a
        }, b), d);
      });
      i.prototype.hb = function (a) {
        return this.set("$transactions", [], a);
      };
      i.prototype.ob = function () {
        if (this.ya()) return this.k({
          $delete: this.d.H()
        });
        p.error("mixpanel.people.delete_user() requires you to call identify() first");
      };
      i.prototype.toString = function () {
        return this.d.toString() + ".people";
      };
      i.prototype.k = function (a, b) {
        a.$token = this.v("token");
        a.$distinct_id = this.d.H();
        var d = this.d.s("$device_id"),
          e = this.d.s("$user_id"),
          f = this.d.s("$had_persisted_distinct_id");
        d && (a.$device_id = d);
        e && (a.$user_id = e);
        f && (a.$had_persisted_distinct_id = f);
        d = c.Ga(a);
        return !this.ya() ? (this.ec(a), c.g(b) || (this.v("verbose") ? b({
          status: -1,
          error: q
        }) : b(-1)), c.truncate(d, 255)) : this.d.Aa({
          type: "people",
          data: d,
          C: this.v("api_host") + "/engage/",
          Ca: this.d.p.Fc
        }, b);
      };
      i.prototype.v = function (a) {
        return this.d.c(a);
      };
      i.prototype.ya = function () {
        return this.d.O.rb === l;
      };
      i.prototype.ec = function (a) {
        "$set" in a ? this.d.persistence.q("$set", a) : "$set_once" in a ? this.d.persistence.q("$set_once", a) : "$unset" in a ? this.d.persistence.q("$unset", a) : "$add" in a ? this.d.persistence.q("$add", a) : "$append" in a ? this.d.persistence.q("$append", a) : "$remove" in a ? this.d.persistence.q("$remove", a) : "$union" in a ? this.d.persistence.q("$union", a) : p.error("Invalid call to _enqueue():", a);
      };
      i.prototype.P = function (a, b, d, e) {
        var f = this,
          g = c.extend({}, this.d.persistence.ca(a)),
          i = g;
        !c.g(g) && c.e(g) && !c.ka(g) && (f.d.persistence.w(a, g), e && (i = e(g)), b.call(f, i, function (b, e) {
          0 === b && f.d.persistence.q(a, g);
          c.g(d) || d(b, e);
        }));
      };
      i.prototype.fc = function (a, b, d, e, f, g, i) {
        var h = this,
          j = this.d.persistence.ca("$append"),
          o = this.d.persistence.ca("$remove");
        this.P("$set", this.set, a);
        this.P("$set_once", this.na, e);
        this.P("$unset", this.qa, g, function (a) {
          return c.keys(a);
        });
        this.P("$add", this.ub, b);
        this.P("$union", this.Z, f);
        if (!c.g(j) && c.isArray(j) && j.length) {
          for (var m, a = function (a, b) {
              0 === a && h.d.persistence.q("$append", m);
              c.g(d) || d(a, b);
            }, b = j.length - 1; 0 <= b; b--) m = j.pop(), c.ka(m) || h.append(m, a);
          h.d.persistence.save();
        }
        if (!c.g(o) && c.isArray(o) && o.length) {
          for (var v, j = function (a, b) {
              0 === a && h.d.persistence.q("$remove", v);
              c.g(i) || i(a, b);
            }, a = o.length - 1; 0 <= a; a--) v = o.pop(), c.ka(v) || h.remove(v, j);
          h.d.persistence.save();
        }
      };
      i.prototype.z = function (a) {
        return "$distinct_id" === a || "$token" === a || "$device_id" === a || "$user_id" === a || "$had_persisted_distinct_id" === a;
      };
      i.prototype.set = i.prototype.set;
      i.prototype.set_once = i.prototype.na;
      i.prototype.unset = i.prototype.qa;
      i.prototype.increment = i.prototype.ub;
      i.prototype.append = i.prototype.append;
      i.prototype.remove = i.prototype.remove;
      i.prototype.union = i.prototype.Z;
      i.prototype.track_charge = i.prototype.cd;
      i.prototype.clear_charges = i.prototype.hb;
      i.prototype.delete_user = i.prototype.ob;
      i.prototype.toString = i.prototype.toString;
      var Pa = "__mps,__mpso,__mpus,__mpa,__mpap,__mpr,__mpu,$people_distinct_id,__alias,__timers".split(",");
      m.prototype.V = function () {
        var a = {};
        c.a(this.props, function (b, d) {
          c.tb(Pa, d) || (a[d] = b);
        });
        return a;
      };
      m.prototype.load = function () {
        if (!this.disabled) {
          var a = this.h.parse(this.name);
          a && (this.props = c.extend({}, a));
        }
      };
      m.prototype.kd = function (a) {
        var b = a.upgrade,
          d;
        if (b) d = "mp_super_properties", "string" === typeof b && (d = b), b = this.h.parse(d), this.h.remove(d), this.h.remove(d, l), b && (this.props = c.extend(this.props, b.all, b.events));
        if (!a.cookie_name && "mixpanel" !== a.name && (d = "mp_" + a.token + "_" + a.name, b = this.h.parse(d))) this.h.remove(d), this.h.remove(d, l), this.u(b);
        this.h === c.localStorage && (b = c.cookie.parse(this.name), c.cookie.remove(this.name), c.cookie.remove(this.name, l), b && this.u(b));
      };
      m.prototype.save = function () {
        this.disabled || this.h.set(this.name, c.aa(this.props), this.Ja, this.Fa, this.Lb, this.mb, this.fa);
      };
      m.prototype.remove = function () {
        this.h.remove(this.name, F, this.fa);
        this.h.remove(this.name, l, this.fa);
      };
      m.prototype.clear = function () {
        this.remove();
        this.props = {};
      };
      m.prototype.u = function (a, b, d) {
        return c.e(a) ? ("undefined" === typeof b && (b = "None"), this.Ja = "undefined" === typeof d ? this.nb : d, c.a(a, function (a, c) {
          if (!this.props.hasOwnProperty(c) || this.props[c] === b) this.props[c] = a;
        }, this), this.save(), l) : F;
      };
      m.prototype.o = function (a, b) {
        return c.e(a) ? (this.Ja = "undefined" === typeof b ? this.nb : b, c.extend(this.props, a), this.save(), l) : F;
      };
      m.prototype.$ = function (a) {
        a in this.props && (delete this.props[a], this.save());
      };
      m.prototype.jd = function () {
        if (!this.gb) this.u(c.info.mc()), this.gb = l;
      };
      m.prototype.$b = function (a) {
        this.o(c.info.Lc(a));
      };
      m.prototype.Va = function (a) {
        this.u({
          $initial_referrer: a || "$direct",
          $initial_referring_domain: c.info.Fb(a) || "$direct"
        }, "");
      };
      m.prototype.vc = function () {
        return c.pa({
          $initial_referrer: this.props.$initial_referrer,
          $initial_referring_domain: this.props.$initial_referring_domain
        });
      };
      m.prototype.Zb = function (a) {
        this.nb = this.Ja = a.cookie_expiration;
        this.Ob(a.disable_persistence);
        this.Qc(a.cookie_domain);
        this.Rc(a.cross_site_cookie);
        this.Sc(a.cross_subdomain_cookie);
        this.Vc(a.secure_cookie);
      };
      m.prototype.Ob = function (a) {
        (this.disabled = a) ? this.remove() : this.save();
      };
      m.prototype.Qc = function (a) {
        if (a !== this.fa) this.remove(), this.fa = a, this.save();
      };
      m.prototype.Rc = function (a) {
        if (a !== this.mb) this.mb = a, this.remove(), this.save();
      };
      m.prototype.Sc = function (a) {
        if (a !== this.Fa) this.Fa = a, this.remove(), this.save();
      };
      m.prototype.tc = function () {
        return this.Fa;
      };
      m.prototype.Vc = function (a) {
        if (a !== this.Lb) this.Lb = a ? l : F, this.remove(), this.save();
      };
      m.prototype.q = function (a, b) {
        var d = this.va(a),
          e = b[a],
          f = this.B("$set"),
          g = this.B("$set_once"),
          i = this.B("$unset"),
          h = this.B("$add"),
          j = this.B("$union"),
          o = this.B("$remove", []),
          m = this.B("$append", []);
        "__mps" === d ? (c.extend(f, e), this.w("$add", e), this.w("$union", e), this.w("$unset", e)) : "__mpso" === d ? (c.a(e, function (a, b) {
          b in g || (g[b] = a);
        }), this.w("$unset", e)) : "__mpus" === d ? c.a(e, function (a) {
          c.a([f, g, h, j], function (b) {
            a in b && delete b[a];
          });
          c.a(m, function (b) {
            a in b && delete b[a];
          });
          i[a] = l;
        }) : "__mpa" === d ? (c.a(e, function (a, b) {
          b in f ? f[b] += a : (b in h || (h[b] = 0), h[b] += a);
        }, this), this.w("$unset", e)) : "__mpu" === d ? (c.a(e, function (a, b) {
          c.isArray(a) && (b in j || (j[b] = []), j[b] = j[b].concat(a));
        }), this.w("$unset", e)) : "__mpr" === d ? (o.push(e), this.w("$append", e)) : "__mpap" === d && (m.push(e), this.w("$unset", e));
        p.log("MIXPANEL PEOPLE REQUEST (QUEUED, PENDING IDENTIFY):");
        p.log(b);
        this.save();
      };
      m.prototype.w = function (a, b) {
        var d = this.ca(a);
        c.g(d) || (c.a(b, function (b, e) {
          "$append" === a || "$remove" === a ? c.a(d, function (a) {
            a[e] === b && delete a[e];
          }) : delete d[e];
        }, this), this.save());
      };
      m.prototype.va = function (a) {
        if ("$set" === a) return "__mps";
        if ("$set_once" === a) return "__mpso";
        if ("$unset" === a) return "__mpus";
        if ("$add" === a) return "__mpa";
        if ("$append" === a) return "__mpap";
        if ("$remove" === a) return "__mpr";
        if ("$union" === a) return "__mpu";
        p.error("Invalid queue:", a);
      };
      m.prototype.ca = function (a) {
        return this.props[this.va(a)];
      };
      m.prototype.B = function (a, b) {
        var d = this.va(a),
          b = c.g(b) ? {} : b;
        return this.props[d] || (this.props[d] = b);
      };
      m.prototype.Tc = function (a) {
        var b = this.props.__timers || {};
        b[a] = new Date().getTime();
        this.props.__timers = b;
        this.save();
      };
      m.prototype.Ic = function (a) {
        var b = (this.props.__timers || {})[a];
        c.g(b) || (delete this.props.__timers[a], this.save());
        return b;
      };
      var ca,
        w,
        N = A.XMLHttpRequest && "withCredentials" in new XMLHttpRequest(),
        ma = !N && -1 === z.indexOf("MSIE") && -1 === z.indexOf("Mozilla"),
        ba = q;
      E.sendBeacon && (ba = function () {
        return E.sendBeacon.apply(E, arguments);
      });
      var za = {
          api_host: "https://api-js.mixpanel.com",
          api_method: "POST",
          api_transport: "XHR",
          api_payload_format: "base64",
          app_host: "https://mixpanel.com",
          cdn: "https://cdn.mxpnl.com",
          cross_site_cookie: F,
          cross_subdomain_cookie: l,
          error_reporter: O,
          persistence: "cookie",
          persistence_name: "",
          cookie_domain: "",
          cookie_name: "",
          loaded: O,
          store_google: l,
          save_referrer: l,
          test: F,
          verbose: F,
          img: F,
          debug: F,
          track_links_timeout: 300,
          cookie_expiration: 365,
          upgrade: F,
          disable_persistence: F,
          disable_cookie: F,
          secure_cookie: F,
          ip: l,
          opt_out_tracking_by_default: F,
          opt_out_persistence_by_default: F,
          opt_out_tracking_persistence_type: "localStorage",
          opt_out_tracking_cookie_prefix: q,
          property_blacklist: [],
          xhr_headers: {},
          ignore_dnt: F,
          batch_requests: l,
          batch_size: 50,
          batch_flush_interval_ms: 5E3,
          batch_request_timeout_ms: 9E4,
          batch_autostart: l,
          hooks: {}
        },
        la = F;
      e.prototype.La = function (a, b, d) {
        if (c.g(d)) this.l("You must name your new library: init(token, config, name)");else if ("mixpanel" === d) this.l("You must initialize the main mixpanel object right after you include the Mixpanel js snippet");else return a = T(a, b, d), w[d] = a, a.da(), a;
      };
      e.prototype.R = function (a, b, d) {
        b = b || {};
        this.__loaded = l;
        this.config = {};
        var e = {};
        "api_payload_format" in b || (b.api_host || za.api_host).match(/\.mixpanel\.com$/) && (e.api_payload_format = "json");
        this.Nb(c.extend({}, za, e, b, {
          name: d,
          token: a,
          callback_fn: ("mixpanel" === d ? d : "mixpanel." + d) + "._jsc"
        }));
        this._jsc = O;
        this.sa = [];
        this.ta = [];
        this.ra = [];
        this.O = {
          disable_all_events: F,
          identify_called: F
        };
        this.p = {};
        if (this.N = this.c("batch_requests")) if (!c.localStorage.la(l) || !N) this.N = F, p.log("Turning off Mixpanel request-queueing; needs XHR and localStorage support");else if (this.yc(), ba && A.addEventListener) {
          var f = c.bind(function () {
            this.p.ha.oa || this.p.ha.flush({
              Xb: l
            });
          }, this);
          A.addEventListener("pagehide", function (a) {
            a.persisted && f();
          });
          A.addEventListener("visibilitychange", function () {
            "hidden" === t.visibilityState && f();
          });
        }
        this.persistence = this.cookie = new m(this.config);
        this.K = {};
        this.gc();
        a = c.Xa();
        this.H() || this.u({
          distinct_id: a,
          $device_id: a
        }, "");
      };
      e.prototype.da = function () {
        this.c("loaded")(this);
        this.eb();
      };
      e.prototype.eb = function () {
        this.persistence.$b(t.referrer);
        this.c("store_google") && this.persistence.jd();
        this.c("save_referrer") && this.persistence.Va(t.referrer);
      };
      e.prototype.dc = function () {
        c.a(this.sa, function (a) {
          this.za.apply(this, a);
        }, this);
        this.U() || c.a(this.ta, function (a) {
          this.k.apply(this, a);
        }, this);
        delete this.sa;
        delete this.ta;
      };
      e.prototype.za = function (a, b) {
        if (this.c("img")) return this.l("You can't use DOM tracking functions with img = true."), F;
        if (!la) return this.sa.push([a, b]), F;
        var c = new a().La(this);
        return c.m.apply(c, b);
      };
      e.prototype.ab = function (a, b) {
        if (c.g(a)) return q;
        if (N) return function (c) {
          a(c, b);
        };
        var d = this._jsc,
          e = "" + Math.floor(1E8 * Math.random()),
          f = this.c("callback_fn") + "[" + e + "]";
        d[e] = function (c) {
          delete d[e];
          a(c, b);
        };
        return f;
      };
      e.prototype.k = function (a, b, d, e) {
        var f = l;
        if (ma) return this.ta.push(arguments), f;
        var g = {
            method: this.c("api_method"),
            Ua: this.c("api_transport"),
            ac: this.c("verbose")
          },
          i = q;
        if (!e && (c.Ma(d) || "string" === typeof d)) e = d, d = q;
        d = c.extend(g, d || {});
        if (!N) d.method = "GET";
        var g = "POST" === d.method,
          h = ba && g && "sendbeacon" === d.Ua.toLowerCase(),
          j = d.ac;
        b.verbose && (j = l);
        this.c("test") && (b.test = 1);
        j && (b.verbose = 1);
        this.c("img") && (b.img = 1);
        if (!N) if (e) b.callback = e;else if (j || this.c("test")) b.callback = "(function(){})";
        b.ip = this.c("ip") ? 1 : 0;
        b._ = new Date().getTime().toString();
        g && (i = "data=" + encodeURIComponent(b.data), delete b.data);
        var a = a + ("?" + c.bc(b)),
          o = this;
        if ("img" in b) i = t.createElement("img"), i.src = a, t.body.appendChild(i);else if (h) {
          try {
            f = ba(a, i);
          } catch (m) {
            o.l(m), f = F;
          }
          try {
            e && e(f ? 1 : 0);
          } catch (p) {
            o.l(p);
          }
        } else if (N) try {
          var u = new XMLHttpRequest();
          u.open(d.method, a, l);
          var s = this.c("xhr_headers");
          g && (s["Content-Type"] = "application/x-www-form-urlencoded");
          c.a(s, function (a, b) {
            u.setRequestHeader(b, a);
          });
          if (d.Ub && "undefined" !== typeof u.timeout) {
            u.timeout = d.Ub;
            var w = new Date().getTime();
          }
          u.withCredentials = l;
          u.onreadystatechange = function () {
            if (4 === u.readyState) if (200 === u.status) {
              if (e) if (j) {
                var a;
                try {
                  a = c.M(u.responseText);
                } catch (b) {
                  if (o.l(b), d.xc) a = u.responseText;else return;
                }
                e(a);
              } else e(Number(u.responseText));
            } else a = u.timeout && !u.status && new Date().getTime() - w >= u.timeout ? "timeout" : "Bad HTTP status: " + u.status + " " + u.statusText, o.l(a), e && (j ? e({
              status: 0,
              error: a,
              L: u
            }) : e(0));
          };
          u.send(i);
        } catch (x) {
          o.l(x), f = F;
        } else i = t.createElement("script"), i.type = "text/javascript", i.async = l, i.defer = l, i.src = a, s = t.getElementsByTagName("script")[0], s.parentNode.insertBefore(i, s);
        return f;
      };
      e.prototype.ua = function (a) {
        function b(a, b) {
          c.a(a, function (a) {
            if (c.isArray(a[0])) {
              var d = b;
              c.a(a, function (a) {
                d = d[a[0]].apply(d, a.slice(1));
              });
            } else this[a[0]].apply(this, a.slice(1));
          }, b);
        }
        var d,
          e = [],
          f = [],
          g = [];
        c.a(a, function (a) {
          a && (d = a[0], c.isArray(d) ? g.push(a) : "function" === typeof a ? a.call(this) : c.isArray(a) && "alias" === d ? e.push(a) : c.isArray(a) && -1 !== d.indexOf("track") && "function" === typeof this[d] ? g.push(a) : f.push(a));
        }, this);
        b(e, this);
        b(f, this);
        b(g, this);
      };
      e.prototype.fb = function () {
        return !!this.p.ha;
      };
      e.prototype.yc = function () {
        var a = this.c("token");
        if (!this.fb()) {
          var b = c.bind(function (b) {
            return new C("__mpq_" + a + b.Ra, {
              A: this.config,
              Oc: c.bind(function (a, c, e) {
                this.k(this.c("api_host") + b.C, this.Za(a), c, this.ab(e, a));
              }, this),
              ea: c.bind(function (a) {
                return this.cb("before_send_" + b.type, a);
              }, this),
              G: this.c("error_reporter"),
              Yc: c.bind(this.Sb, this)
            });
          }, this);
          this.p = {
            ha: b({
              type: "events",
              C: "/track/",
              Ra: "_ev"
            }),
            Fc: b({
              type: "people",
              C: "/engage/",
              Ra: "_pp"
            }),
            wc: b({
              type: "groups",
              C: "/groups/",
              Ra: "_gr"
            })
          };
        }
        this.c("batch_autostart") && this.Rb();
      };
      e.prototype.Rb = function () {
        if (this.fb()) this.N = l, c.a(this.p, function (a) {
          a.start();
        });
      };
      e.prototype.Sb = function () {
        this.N = F;
        c.a(this.p, function (a) {
          a.stop();
          a.clear();
        });
      };
      e.prototype.push = function (a) {
        this.ua([a]);
      };
      e.prototype.disable = function (a) {
        "undefined" === typeof a ? this.O.oc = l : this.ra = this.ra.concat(a);
      };
      e.prototype.Za = function (a) {
        a = c.aa(a);
        "base64" === this.c("api_payload_format") && (a = c.kc(a));
        return {
          data: a
        };
      };
      e.prototype.Aa = function (a, b) {
        var d = c.truncate(a.data, 255),
          e = a.C,
          f = a.Ca,
          g = a.Wc,
          i = a.Pc || {},
          b = b || O,
          h = l,
          j = c.bind(function () {
            i.Qb || (d = this.cb("before_send_" + a.type, d));
            return d ? (p.log("MIXPANEL REQUEST:"), p.log(d), this.k(e, this.Za(d), i, this.ab(b, d))) : q;
          }, this);
        this.N && !g ? f.Ha(d, function (a) {
          a ? b(1, d) : j();
        }) : h = j();
        return h && d;
      };
      e.prototype.m = P(function (a, b, d, e) {
        !e && "function" === typeof d && (e = d, d = q);
        var d = d || {},
          f = d.transport;
        if (f) d.Ua = f;
        f = d.send_immediately;
        "function" !== typeof e && (e = O);
        if (c.g(a)) this.l("No event name provided to mixpanel.track");else if (this.$a(a)) e(0);else {
          b = b || {};
          b.token = this.c("token");
          var g = this.persistence.Ic(a);
          c.g(g) || (b.$duration = parseFloat(((new Date().getTime() - g) / 1E3).toFixed(3)));
          this.eb();
          b = c.extend({}, c.info.V(), this.persistence.V(), this.K, b);
          g = this.c("property_blacklist");
          c.isArray(g) ? c.a(g, function (a) {
            delete b[a];
          }) : this.l("Invalid value for property_blacklist config: " + g);
          return this.Aa({
            type: "events",
            data: {
              event: a,
              properties: b
            },
            C: this.c("api_host") + "/track/",
            Ca: this.p.ha,
            Wc: f,
            Pc: d
          }, e);
        }
      });
      e.prototype.Uc = P(function (a, b, d) {
        c.isArray(b) || (b = [b]);
        var e = {};
        e[a] = b;
        this.o(e);
        return this.people.set(a, b, d);
      });
      e.prototype.hc = P(function (a, b, c) {
        var e = this.s(a);
        if (e === k) {
          var f = {};
          f[a] = [b];
          this.o(f);
        } else -1 === e.indexOf(b) && (e.push(b), this.o(f));
        return this.people.Z(a, b, c);
      });
      e.prototype.Jc = P(function (a, b, c) {
        var e = this.s(a);
        if (e !== k) {
          var f = e.indexOf(b);
          -1 < f && (e.splice(f, 1), this.o({
            pd: e
          }));
          0 === e.length && this.$(a);
        }
        return this.people.remove(a, b, c);
      });
      e.prototype.gd = P(function (a, b, d, e) {
        var f = c.extend({}, b || {});
        c.a(d, function (a, b) {
          a !== q && a !== k && (f[b] = a);
        });
        return this.m(a, f, e);
      });
      e.prototype.cc = function (a, b) {
        return a + "_" + JSON.stringify(b);
      };
      e.prototype.uc = function (a, b) {
        var c = this.cc(a, b),
          e = this.Ya[c];
        if (e === k || e.xa !== a || e.wa !== b) e = new s(), e.R(this, a, b), this.Ya[c] = e;
        return e;
      };
      e.prototype.fd = function (a) {
        if (c.g(a)) a = t.location.href;
        this.m("mp_page_view", c.info.Ec(a));
      };
      e.prototype.ed = function () {
        return this.za.call(this, L, arguments);
      };
      e.prototype.dd = function () {
        return this.za.call(this, U, arguments);
      };
      e.prototype.$c = function (a) {
        c.g(a) ? this.l("No event name provided to mixpanel.time_event") : this.$a(a) || this.persistence.Tc(a);
      };
      var Da = {
        persistent: l
      };
      e.prototype.o = function (a, b) {
        var d = da(b);
        d.persistent ? this.persistence.o(a, d.days) : c.extend(this.K, a);
      };
      e.prototype.u = function (a, b, d) {
        d = da(d);
        d.persistent ? this.persistence.u(a, b, d.days) : ("undefined" === typeof b && (b = "None"), c.a(a, function (a, c) {
          if (!this.K.hasOwnProperty(c) || this.K[c] === b) this.K[c] = a;
        }, this));
      };
      e.prototype.$ = function (a, b) {
        b = da(b);
        b.persistent ? this.persistence.$(a) : delete this.K[a];
      };
      e.prototype.bb = function (a, b) {
        var c = {};
        c[a] = b;
        this.o(c);
      };
      e.prototype.Ka = function (a, b, c, e, f, g, i, h) {
        var j = this.H();
        this.o({
          $user_id: a
        });
        this.s("$device_id") || this.u({
          $had_persisted_distinct_id: l,
          $device_id: j
        }, "");
        a !== j && a !== this.s("__alias") && (this.$("__alias"), this.o({
          distinct_id: a
        }));
        this.O.rb = l;
        this.people.fc(b, c, e, f, g, i, h);
        a !== j && this.m("$identify", {
          distinct_id: a,
          $anon_distinct_id: j
        }, {
          Qb: l
        });
      };
      e.prototype.reset = function () {
        this.persistence.clear();
        this.O.rb = F;
        var a = c.Xa();
        this.u({
          distinct_id: a,
          $device_id: a
        }, "");
      };
      e.prototype.H = function () {
        return this.s("distinct_id");
      };
      e.prototype.ic = function (a, b) {
        if (a === this.s("$people_distinct_id")) return this.l("Attempting to create alias for existing People user - aborting."), -2;
        var d = this;
        c.g(b) && (b = this.H());
        if (a !== b) return this.bb("__alias", a), this.m("$create_alias", {
          alias: a,
          distinct_id: b
        }, {
          Qb: l
        }, function () {
          d.Ka(a);
        });
        this.l("alias matches current distinct_id - skipping api call.");
        this.Ka(a);
        return -1;
      };
      e.prototype.Cc = function (a) {
        this.bb("mp_name_tag", a);
      };
      e.prototype.Nb = function (a) {
        if (c.e(a)) c.extend(this.config, a), a.batch_size && c.a(this.p, function (a) {
          a.Jb();
        }), this.c("persistence_name") || (this.config.persistence_name = this.config.cookie_name), this.c("disable_persistence") || (this.config.disable_persistence = this.config.disable_cookie), this.persistence && this.persistence.Zb(this.config), J = J || this.c("debug");
      };
      e.prototype.c = function (a) {
        return this.config[a];
      };
      e.prototype.cb = function (a) {
        var b = (this.config.hooks[a] || Ea).apply(this, K.call(arguments, 1));
        "undefined" === typeof b && (this.l(a + " hook did not return a value"), b = q);
        return b;
      };
      e.prototype.s = function (a) {
        return this.persistence.props[a];
      };
      e.prototype.toString = function () {
        var a = this.c("name");
        "mixpanel" !== a && (a = "mixpanel." + a);
        return a;
      };
      e.prototype.$a = function (a) {
        return c.wb(z) || this.O.oc || c.tb(this.ra, a);
      };
      e.prototype.gc = function () {
        "localStorage" === this.c("opt_out_tracking_persistence_type") && c.localStorage.la() && (!this.ja() && this.ja({
          persistence_type: "cookie"
        }) && this.Ab({
          enable_persistence: F
        }), !this.U() && this.U({
          persistence_type: "cookie"
        }) && this.Pa({
          clear_persistence: F
        }), this.ib({
          persistence_type: "cookie",
          enable_persistence: F
        }));
        if (this.U()) this.ba({
          clear_persistence: l
        });else if (!this.ja() && (this.c("opt_out_tracking_by_default") || c.cookie.get("mp_optout"))) c.cookie.remove("mp_optout"), this.Pa({
          clear_persistence: this.c("opt_out_persistence_by_default")
        });
      };
      e.prototype.ba = function (a) {
        if (a && a.clear_persistence) a = l;else if (a && a.enable_persistence) a = F;else return;
        !this.c("disable_persistence") && this.persistence.disabled !== a && this.persistence.Ob(a);
        a && c.a(this.p, function (a) {
          a.clear();
        });
      };
      e.prototype.Q = function (a, b) {
        b = c.extend({
          track: c.bind(this.m, this),
          persistence_type: this.c("opt_out_tracking_persistence_type"),
          cookie_prefix: this.c("opt_out_tracking_cookie_prefix"),
          cookie_expiration: this.c("cookie_expiration"),
          cross_site_cookie: this.c("cross_site_cookie"),
          cross_subdomain_cookie: this.c("cross_subdomain_cookie"),
          cookie_domain: this.c("cookie_domain"),
          secure_cookie: this.c("secure_cookie"),
          ignore_dnt: this.c("ignore_dnt")
        }, b);
        c.localStorage.la() || (b.persistence_type = "cookie");
        return a(this.c("token"), {
          m: b.track,
          ad: b.track_event_name,
          bd: b.track_properties,
          Db: b.persistence_type,
          Cb: b.cookie_prefix,
          jb: b.cookie_domain,
          kb: b.cookie_expiration,
          nc: b.cross_site_cookie,
          lb: b.cross_subdomain_cookie,
          Mc: b.secure_cookie,
          sb: b.ignore_dnt
        });
      };
      e.prototype.Ab = function (a) {
        a = c.extend({
          enable_persistence: l
        }, a);
        this.Q(Ha, a);
        this.ba(a);
      };
      e.prototype.Pa = function (a) {
        a = c.extend({
          clear_persistence: l,
          delete_user: l
        }, a);
        a.delete_user && this.people && this.people.ya() && (this.people.ob(), this.people.hb());
        this.Q(Ia, a);
        this.ba(a);
      };
      e.prototype.ja = function (a) {
        return this.Q(Ja, a);
      };
      e.prototype.U = function (a) {
        return this.Q(ta, a);
      };
      e.prototype.ib = function (a) {
        a = c.extend({
          enable_persistence: l
        }, a);
        this.Q(La, a);
        this.ba(a);
      };
      e.prototype.l = function (a, b) {
        p.error.apply(p.error, arguments);
        try {
          !b && !(a instanceof Error) && (a = Error(a)), this.c("error_reporter")(a, b);
        } catch (c) {
          p.error(c);
        }
      };
      e.prototype.init = e.prototype.La;
      e.prototype.reset = e.prototype.reset;
      e.prototype.disable = e.prototype.disable;
      e.prototype.time_event = e.prototype.$c;
      e.prototype.track = e.prototype.m;
      e.prototype.track_links = e.prototype.ed;
      e.prototype.track_forms = e.prototype.dd;
      e.prototype.track_pageview = e.prototype.fd;
      e.prototype.register = e.prototype.o;
      e.prototype.register_once = e.prototype.u;
      e.prototype.unregister = e.prototype.$;
      e.prototype.identify = e.prototype.Ka;
      e.prototype.alias = e.prototype.ic;
      e.prototype.name_tag = e.prototype.Cc;
      e.prototype.set_config = e.prototype.Nb;
      e.prototype.get_config = e.prototype.c;
      e.prototype.get_property = e.prototype.s;
      e.prototype.get_distinct_id = e.prototype.H;
      e.prototype.toString = e.prototype.toString;
      e.prototype.opt_out_tracking = e.prototype.Pa;
      e.prototype.opt_in_tracking = e.prototype.Ab;
      e.prototype.has_opted_out_tracking = e.prototype.U;
      e.prototype.has_opted_in_tracking = e.prototype.ja;
      e.prototype.clear_opt_in_out_tracking = e.prototype.ib;
      e.prototype.get_group = e.prototype.uc;
      e.prototype.set_group = e.prototype.Uc;
      e.prototype.add_group = e.prototype.hc;
      e.prototype.remove_group = e.prototype.Jc;
      e.prototype.track_with_groups = e.prototype.gd;
      e.prototype.start_batch_senders = e.prototype.Rb;
      e.prototype.stop_batch_senders = e.prototype.Sb;
      m.prototype.properties = m.prototype.V;
      m.prototype.update_search_keyword = m.prototype.$b;
      m.prototype.update_referrer_info = m.prototype.Va;
      m.prototype.get_cross_subdomain = m.prototype.tc;
      m.prototype.clear = m.prototype.clear;
      var G = {};
      (function () {
        ca = 1;
        w = A.mixpanel;
        c.g(w) ? p.F('"mixpanel" object not initialized. Ensure you are using the latest version of the Mixpanel JS Library along with the snippet we provide.') : w.__loaded || w.config && w.persistence ? p.F("The Mixpanel library has already been downloaded at least once. Ensure that the Mixpanel code snippet only appears once on the page (and is not double-loaded by a tag manager) in order to avoid errors.") : 1.1 > (w.__SV || 0) ? p.F("Version mismatch; please ensure you're using the latest version of the Mixpanel code snippet.") : (c.a(w._i, function (a) {
          a && c.isArray(a) && (G[a[a.length - 1]] = T.apply(this, a));
        }), Ba(), w.init(), c.a(G, function (a) {
          a.da();
        }), Aa());
      })();
    })();
  };
  _exports.MixpanelSource = MixpanelSource;
});