define("discourse/plugins/karma-score/lib/fetch-snapshot-onchain-ids", ["exports", "discourse/plugins/karma-score/lib/stats/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fetchDaoSnapshotAndOnChainIds = fetchDaoSnapshotAndOnChainIds;
  async function fetchDaoSnapshotAndOnChainIds(daoName) {
    const url = `${_index.default.url}/dao/${daoName}`.toLowerCase();
    const {
      data
    } = await fetch(url).then(res => res.json());
    return data ?? {
      onChainId: null,
      snapshotIds: null
    };
  }
});