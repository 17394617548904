define("discourse/plugins/karma-score/lib/is-eth-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEthAddress = isEthAddress;
  /**
   * Checks if the given string is an Eth Address
   *
   * @method isAddress
   * @param {String} address the given HEX adress
   * @return {Boolean}
   */
  function isEthAddress(address) {
    return /^(0x)?[0-9a-f]{40}$/i.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address);
  }
});