define("discourse/plugins/karma-score/lib/vote-breakdown", ["exports", "discourse/plugins/karma-score/lib/snapshot/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getResults = getResults;
  _exports.getScores = getScores;
  _exports.getVoteBreakdownByProposal = _exports.getVoteBreakdown = void 0;
  const scoreApiUrl = "https://score.snapshot.org/api/scores";
  async function getScores() {
    let space = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
    let strategies = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    let network = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
    let addresses = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    let snapshot = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "latest";
    try {
      const params = {
        space,
        network,
        snapshot,
        strategies,
        addresses
      };
      const res = await fetch(scoreApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          params
        })
      });
      const obj = await res.json();
      return obj.result.scores;
    } catch (e) {
      // eslint-disable-next-line no-restricted-globals
      return Promise.reject(e);
    }
  }
  async function getResults(space, proposal, votes) {
    const voters = votes.map(vote => vote.voter);
    const strategies = proposal.strategies ?? space.strategies;
    /* Get scores */
    if (proposal.state !== "pending") {
      const scores = await getScores(space.id, strategies, proposal.network, voters,
      // eslint-disable-next-line radix
      parseInt(proposal.snapshot));
      votes = votes.map(vote => {
        vote.scores = strategies.map((_strategy, i) => scores[i][vote.voter] || 0);
        vote.balance = vote.scores.reduce((a, b) => a + b, 0);
        return vote;
      }).sort((a, b) => b.balance - a.balance).filter(vote => vote.balance > 0);
    }

    /* Get results */
    const votingClass = new _index.default[proposal.proposalType](proposal, votes, strategies);
    proposal.scores = votingClass.getScores();
    return proposal;
  }
  const getChoices = function () {
    let choices = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    const vb = {};
    choices.forEach(choice => vb[choice] = 0);
    vb.total = 0;
    return vb;
  };

  /**
   * Parses the votes into breakdown sections in order to display
   * each option as a separate option in the UI.
   *
   * @param {*} votes
   * @param {*} choices
   * @returns
   */
  const getVoteBreakdown = function () {
    let votes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    let choices = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ["For", "Against", "Abstain"];
    const vb = getChoices(choices);
    votes.forEach(item => {
      let choiceIdx = +item.choice;
      if (+item.choice === 0) {
        choiceIdx = 1;
      } else if (+item.choice === 1) {
        choiceIdx = 0;
      }
      const rValue = +item.weight / 1e18;
      vb[choices[choiceIdx]] += rValue;
      vb.total += rValue;
    });
    return vb;
  };
  _exports.getVoteBreakdown = getVoteBreakdown;
  const getVoteBreakdownByProposal = function () {
    let proposal = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const vb = getChoices(proposal.choices);
    const keys = Object.keys(vb);
    vb.total = 0;
    proposal.scores.forEach((score, idx) => {
      vb[keys[idx]] = score;
      vb.total += score;
    });
    proposal.voteBreakdown = vb;
    return proposal;
  };
  _exports.getVoteBreakdownByProposal = getVoteBreakdownByProposal;
});