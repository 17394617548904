define("discourse/plugins/karma-score/lib/fetch-user-threads", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fetchUserThreads;
  /**
   * Fetch threads user has created
   * @returns
   */
  function fetchUserThreads(username) {
    return fetch(`/topics/created-by/${username}.json`, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json; charset=UTF-8"
      },
      method: "GET",
      mode: "cors"
    }).then(res => res.json());
  }
});