define("discourse/plugins/karma-score/lib/voting-history/gql/off-chain-fetcher", ["exports", "discourse/plugins/karma-score/lib/voting-history/gql/fetcher", "discourse/plugins/karma-score/lib/voting-history/gql/queries", "discourse/plugins/karma-score/lib/parse-md-link", "discourse/plugins/karma-score/lib/date-diff"], function (_exports, _fetcher, _queries, _parseMdLink, _dateDiff) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fetchActiveOffChainProposals = fetchActiveOffChainProposals;
  _exports.fetchOffChainProposalVotes = fetchOffChainProposalVotes;
  /* eslint-disable no-restricted-globals */

  const subgraphUrl = new URL("https://hub.snapshot.org/graphql");

  /**
   * Concat proposal and votes into a common interface
   * @param proposals
   * @param votes
   * @returns {import("karma-score").ParsedProposal[])}
   */
  function parseVotes() {
    let votes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    const array = [];
    votes.forEach(vote => {
      const {
        proposal
      } = vote;
      array.push({
        title: proposal?.title,
        proposalId: proposal.id,
        voteMethod: "Off-chain",
        proposal: (0, _parseMdLink.parseMdLink)(proposal?.title),
        choice: Array.isArray(vote.choice) || Object.keys(vote.choice || {}).length ? "Multiple" : proposal.choices[vote.choice - 1]?.toUpperCase(),
        executed: moment.unix(proposal.end).format("MMMM D, YYYY")
      });
    });
    return array;
  }

  /**
   * Fetch proposals from the subgraph
   * @param daoName
   * @returns array of voted and not voted proposals (not sorted)
   */
  async function fetchOffChainProposalVotes() {
    let daoNames = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    let address = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
    let amount = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 3;
    try {
      const votesQuery = _queries.history.offChain.votes(address, daoNames, amount);
      const {
        votes
      } = await _fetcher.default.query(subgraphUrl, votesQuery);
      if (votes && Array.isArray(votes)) {
        return parseVotes(votes);
      }
      return [];
    } catch (error) {
      throw error;
      //
    }
  }
  const paginateVote = async function () {
    let proposals = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    const loop = async function () {
      let acc = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      let page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      const voteBreakdownQuery = _queries.proposal.offChain.votes(proposals.map(p => p.id), page);
      const {
        votes
      } = await _fetcher.default.query(subgraphUrl, voteBreakdownQuery);
      if (Array.isArray(votes)) {
        acc.push(...votes);
      }
      if (votes?.length === 1000) {
        await loop(acc, page + 1);
      }
      return acc;
    };
    const result = await loop();
    return result;
  };
  const withVoteBreakdown = async function () {
    let proposals = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    if (proposals.length) {
      const votes = await paginateVote(proposals);
      const {
        spaces
      } = await _fetcher.default.query(subgraphUrl, _queries.proposal.offChain.strategies([proposals[0].snapshotId]));
      const [space] = spaces;
      if (votes && Array.isArray(votes)) {
        proposals = proposals.map(proposal => {
          proposal.votes = votes.filter(item => item.proposal.id === proposal.id);
          proposal.space = space;
          return proposal;
        });
        return proposals;
      }
    }
    return [];
  };
  const parseProposals = function () {
    let proposals = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return proposals.map(proposal => ({
      id: proposal.id,
      type: "Off-chain",
      title: (0, _parseMdLink.parseMdLink)(proposal.title),
      shortname: proposal.title.slice(0, 40) + "...",
      voteCount: proposal.votes,
      voteBreakdown: {
        For: 0,
        Abstain: 0,
        Against: 0,
        total: 0
      },
      endsAt: moment.unix(proposal.endsAt).format("MMM D, YYYY"),
      voteStarts: moment.unix(proposal.start).format("MMM D, YYYY"),
      dateDescription: (0, _dateDiff.dateDiff)(proposal.endsAt),
      snapshotId: proposal.space.id,
      choices: proposal.choices,
      strategies: proposal.strategies,
      network: proposal.network,
      snapshot: proposal.snapshot,
      proposalType: proposal.type
    }));
  };
  async function fetchActiveOffChainProposals(daoNames, daysAgo) {
    try {
      const proposalsQuery = _queries.proposal.offChain.proposal(daoNames, undefined, daysAgo);
      const {
        proposals
      } = await _fetcher.default.query(subgraphUrl, proposalsQuery);
      if (proposals && Array.isArray(proposals)) {
        return withVoteBreakdown(parseProposals(proposals));
      }
      return [];
    } catch (error) {
      throw error;
      //
    }
  }
});