define("discourse/plugins/karma-score/discourse/components/vote-breakdown", ["exports", "@ember/component", "@ember/object", "discourse/plugins/karma-score/lib/shorten-number"], function (_exports, _component, _object, _shortenNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    breakdown: {},
    values: [],
    loading: false,
    proposalUrl: "",
    sortVotes(votes) {
      if (Array.isArray(votes) && !(votes[0].name === "For" || votes[1].name === "Against")) {
        return votes.sort((a, b) => a.rawCount < b.rawCount ? 1 : -1);
      }
      return votes;
    },
    truncateVotesArray(votes) {
      if (Array.isArray(votes) && votes.length > 4) {
        const last = {
          name: "",
          shortname: "Others",
          rawCount: 0,
          count: "0",
          pct: "",
          fillPct: "0",
          color: votes[0].color
        };
        votes.splice(3, votes.length).forEach(vote => {
          last.name += `<b>${vote.name}</b>: ${vote.count} votes <br>`;
          last.rawCount += vote.rawCount;
        });
        const pct = ((last.rawCount || 0) / (this.breakdown.total || 1) * 100).toFixed(2);
        last.count = (0, _shortenNumber.shortenNumber)(last.rawCount);
        last.pct = pct + "%";
        last.fillPct = (pct >= 1 ? pct : 1) + "%";
        return votes.concat(last);
      }
      return votes;
    },
    didReceiveAttrs() {
      const keys = Object.keys(this.breakdown).map(k => k);
      // const rgb = (i) =>
      //   `rgba(82, 152, 255, ${i === 0 ? 1 : "0." + (10 - (i + 1))})`;
      const votesPct = keys.filter(key => !["undefined", "total"].includes(key)).map(key => {
        const pct = +((+this.breakdown[key] || 0) / (+this.breakdown.total || 1) * 100).toFixed(2);
        return {
          name: key,
          shortname: `${key.slice(0, 20).trim()}${key[20] && key[20] !== " " ? "..." : ""}`,
          rawCount: this.breakdown[key],
          count: (0, _shortenNumber.shortenNumber)(this.breakdown[key], 1),
          pct: pct + "%",
          fillPct: (pct >= 1 ? pct : 1) + "%",
          color: "rgba(82, 152, 255, 0.15)"
        };
      });
      const truncatedArray = this.truncateVotesArray(this.sortVotes(votesPct));
      (0, _object.set)(this, "values", truncatedArray);
    },
    redirect() {
      window.open(this.link, "_blank");
    }
  }, [["method", "redirect", [_object.action]]]));
});