define("discourse/plugins/karma-score/lib/snapshot/BaseScore", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BaseScore = void 0;
  class BaseScore {
    proposal = {
      choices: []
    };
    votes = [];
    strategies = [];
    selected = [];
    constructor() {
      let proposal = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        choices: []
      };
      let votes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      let strategies = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      let selected = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
      this.proposal = proposal;
      this.votes = votes;
      this.strategies = strategies;
      this.selected = selected;
    }
  }
  _exports.BaseScore = BaseScore;
});