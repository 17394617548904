define("discourse/plugins/karma-score/lib/request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.request = request;
  /**
   * Creates a fetch request
   * @param {string} url target url
   * @param {*} body requets body. NULL if GET,DELETE
   * @param {"POST"|"GET"|"PUT"|"DELETE"|"PATCH"} method request method
   * @param {*} headers request headers
   * @returns
   */
  async function request(url, body) {
    let method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "POST";
    let headers = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    const data = await fetch(url, {
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        "Content-Type": "application/json",
        ...headers
      }
    }).then(async res => await res.json());
    if ("error" in data || "errors" in data) {
      throw new Error(data.error ?? data.errors[0]);
    }
    return {
      ...data.data
    };
  }
});