define("discourse/plugins/karma-score/lib/post-to-topic", ["exports", "discourse/plugins/karma-score/lib/is-typeof"], function (_exports, _isTypeof) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = postToTopic;
  function validate(_ref) {
    let {
      threadId,
      body,
      csrf
    } = _ref;
    (0, _isTypeof.isTypeof)(body, "string");
    (0, _isTypeof.isTypeof)(csrf, "string");
    (0, _isTypeof.isTypeof)(threadId, "number");
    const errors = [];
    ["threadId", "body", "csrf"].forEach(arg => typeof arguments[0][arg] === "undefined" && errors.push(arg));
    if (errors.length) {
      throw new Error(`Something is missing from params: ${errors.join(",")}`);
    }
    if (body.length < 20) {
      throw new Error("Body should have at least 20 chars.");
    }
  }

  /**
   * Post to a topic on discourse
   * @returns
   */
  function postToTopic(_ref2) {
    let {
      /**
       * The topic/thread id
       */
      threadId,
      /**
       * The body content. Must be at least 20 char
       */
      body,
      /**
       * CSRF token provided by the session (`this.session.csrfToken`)
       */
      csrf
    } = _ref2;
    validate(...arguments);
    const reqBody = {
      raw: body,
      topic_id: threadId
    };
    return fetch("/posts.json", {
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrf,
        "Content-Type": "application/json; charset=UTF-8"
      },
      body: JSON.stringify(reqBody),
      method: "POST",
      mode: "cors"
    }).then(res => res.json()).catch(e => {
      throw e;
    });
  }
});