define("discourse/plugins/karma-score/lib/voting-history/gql/on-chain-fetcher", ["exports", "discourse/plugins/karma-score/lib/voting-history/gql/fetcher", "discourse/plugins/karma-score/lib/voting-history/gql/queries", "discourse/plugins/karma-score/lib/parse-md-link", "discourse/plugins/karma-score/lib/date-diff", "discourse/plugins/karma-score/lib/vote-breakdown"], function (_exports, _fetcher, _queries, _parseMdLink, _dateDiff, _voteBreakdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fetchActiveOnChainProposals = fetchActiveOnChainProposals;
  _exports.fetchOnChainProposalVotes = fetchOnChainProposalVotes;
  /* eslint-disable no-restricted-globals */

  const subgraphUrl = new URL("https://api.karmahq.xyz/api/dao/discourse/plugin");

  /**
   * Concat proposal and votes into a common interface
   * @param proposals
   * @param votes
   */
  function parseVotes() {
    let votes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    const array = [];
    votes.forEach(vote => {
      const {
        proposal
      } = vote;
      array.push({
        title: proposal?.description?.split(/\n/)[0]?.replace(/\#+/gim, ""),
        proposalId: proposal.id,
        voteMethod: "On-chain",
        proposal: (0, _parseMdLink.parseMdLink)(proposal?.description),
        choice: vote?.support,
        solution: vote?.solution,
        executed: moment.unix(proposal.timestamp).format("MMMM D, YYYY")
      });
    });
    return array;
  }

  /**
   * Fetch proposals from the subgraph
   * @param daoName
   * @returns array of voted and not voted proposals (not sorted)
   */
  async function fetchOnChainProposalVotes() {
    let daoNames = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    let address = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
    let amount = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 3;
    try {
      const votesQuery = _queries.history.onChain.votes(address, daoNames, amount);
      const {
        votes
      } = await _fetcher.default.query(subgraphUrl, votesQuery);
      if (votes && Array.isArray(votes)) {
        return parseVotes(votes);
      }
      return [];
    } catch (error) {
      throw error;
      //
    }
  }
  const getProposalTitle = proposal => {
    const parts = proposal?.title?.split(/\n/);
    if (parts) {
      for (const title of parts) {
        if (title.replace(/\s+/, "").length) {
          return title?.replace(/\#+/gim, "");
        }
      }
    }
    return "No title";
  };
  const parseProposals = function () {
    let proposals = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return proposals.map(proposal => ({
      id: proposal.id.split("-")[1],
      type: "On-chain",
      title: getProposalTitle(proposal),
      shortname: proposal.title.slice(0, 40) + "...",
      voteCount: proposal.votes.length,
      voteBreakdown: (0, _voteBreakdown.getVoteBreakdown)(proposal.votes),
      endsAt: proposal.endsAt ? moment.unix(proposal.endsAt).format("MMM D, YYYY") : null,
      voteStarts: moment.unix(proposal.endsAt).format("MMM D, YYYY"),
      dateDescription: (0, _dateDiff.dateDiff)(proposal.endsAt),
      snapshotId: proposal.organization.id
    }));
  };
  async function fetchActiveOnChainProposals(daoNames, daysAgo) {
    try {
      const perPage = 1000;
      const proposalsQuery = _queries.proposal.onChain.proposal(daoNames, undefined, daysAgo, perPage);
      const {
        proposals
      } = await _fetcher.default.query(subgraphUrl, proposalsQuery);
      if (proposals && Array.isArray(proposals)) {
        const promises = proposals.filter(proposal => proposal.votes && proposal.votes.length >= perPage).map(async proposal => {
          while (true) {
            const votesQuery = _queries.history.onChain.proposalVotes(proposal.id, perPage, proposal.votes.at(-1).timestamp);
            const {
              votes
            } = await _fetcher.default.query(subgraphUrl, votesQuery);
            proposal.votes.push(...votes);
            if (votes.length < perPage) {
              break;
            }
          }
        });
        await Promise.all(promises);
        return parseProposals(proposals);
      }
      return [];
    } catch (error) {
      throw error;
      //
    }
  }
});