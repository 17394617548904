define("discourse/plugins/karma-score/lib/voting-history/gql/queries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.proposal = _exports.history = void 0;
  function inStatement(array) {
    return `["${array.join('","')}"]`;
  }
  function yesterdayUTC() {
    let daysAgo = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    return daysAgo === 0 ? moment().unix() : moment.unix(Math.floor(new Date().setUTCHours(0, 0, 0, 0) / 1000)).subtract(daysAgo >= 0 ? daysAgo : 0, "days").unix();
  }
  const proposal = _exports.proposal = {
    /* where: { organization_in: ${inStatement(daoNames)}, status: "Active" } }*/
    onChain: {
      proposal: function () {
        let daoNames = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        let amount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
        let daysAgo = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
        let voteAmount = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1000;
        return `query Proposals {
      proposals(
        first: ${amount},
        where: {
          and: [
            {
              or: [
                {timestamp_gt: ${yesterdayUTC(daysAgo)}},
                {status: "Active", endDate: null}
              ]
            },
            {id_not_in: ["ens.eth-0x3d92e95f813c9e79938934d81310dd40f0f444356b21c1bb23f26394236c36c7"]},
            {organization_in: ${inStatement(daoNames)}}
          ]
        }

      ) {
        id
        status
        title: description
        endsAt: endDate
        votes(
          first:${voteAmount},
          orderBy:timestamp,
          orderDirection:desc
        ) {
          choice: support,
          weight,
          timestamp
        }
        organization {id}
      }
    }`;
      }
    },
    offChain: {
      proposal: function () {
        let daoNames = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        let amount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
        let daysAgo = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
        return `query Proposals {
      proposals(
        first: ${amount},
        where: { space_in: ${inStatement(daoNames)}, end_gt: ${yesterdayUTC(daysAgo)} }) {        id
        title
        state
        endsAt: end
        votes
        space {id}
        choices
        network
        start
        snapshot
        type
        strategies {
          name
          network
          params
        }
      }
    }`;
      },
      votes: function () {
        let proposalIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        let page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
        return `query Votes {
      votes(first:1000, skip:${page * 1000} ,where: {proposal_in: ${inStatement(proposalIds)}}){
        choice
        vp
        vp_by_strategy
        vp_state
        proposal {id}
        voter
      }
    }`;
      },
      strategies: function () {
        let daoNames = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        return `query Strategies {
      spaces (where: {
        id_in: ${inStatement(daoNames)}
      }) {
        id
        strategies {
          name
          network
          params
        }
      }
    }`;
      }
    }
  };
  const history = _exports.history = {
    offChain: {
      votes: function () {
        let address = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
        let daoNames = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
        let amount = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 3;
        return `query Votes {
        votes(
          first: ${amount},
          where: {
            space_in: ${inStatement(daoNames)},
            voter: "${address}"
          }
        ) {
          choice
          voter
          proposal {
            id
            title
            choices
            state
            end
          }
        }
      }
    `;
      }
    },
    onChain: {
      proposalVotes: function (proposalId) {
        let amount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
        let timestampLt = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : (Date.now() / 1000).toFixed(0);
        return `query ProposalVotes {
      votes(
        first: ${amount}
				orderBy: timestamp
				orderDirection: desc
				where: {proposal: "${proposalId}", timestamp_lt: ${timestampLt} }
			) {
				id
				solution
				timestamp
				support
			}
    }`;
      },
      votes: function () {
        let address = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
        let daoNames = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
        let amount = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 3;
        let timestampLt = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : (Date.now() / 1000).toFixed(0);
        return `query Votes {
      votes(
        first: ${amount}
				orderBy: timestamp
				orderDirection: desc
				where: { user: "${address}", organization_in: ${inStatement(daoNames)}, timestamp_lt: ${timestampLt} }
			) {
				id
				proposal {
					id
					description
					timestamp
				}
				organization {
					id
				}
				solution
				timestamp
				support
			}
    }`;
      }
    }
  };
});