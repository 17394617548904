define("discourse/plugins/karma-score/lib/snapshot/index", ["exports", "discourse/plugins/karma-score/lib/snapshot/singleChoice", "discourse/plugins/karma-score/lib/snapshot/approval", "discourse/plugins/karma-score/lib/snapshot/quadratic", "discourse/plugins/karma-score/lib/snapshot/rankedChoice", "discourse/plugins/karma-score/lib/snapshot/weighted"], function (_exports, _singleChoice, _approval, _quadratic, _rankedChoice, _weighted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    'single-choice': _singleChoice.default,
    approval: _approval.default,
    quadratic: _quadratic.default,
    'ranked-choice': _rankedChoice.default,
    weighted: _weighted.default,
    basic: _singleChoice.default
  };
});