define("discourse/plugins/karma-score/discourse/components/karma-stats", ["exports", "@ember/component", "@ember/service", "@ember/object", "discourse/plugins/karma-score/lib/stats/index"], function (_exports, _component, _service, _object, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    router: (0, _service.inject)(),
    profile: {},
    wrapperId: "__karma-stats-summary",
    hasSetApiKey: false,
    shouldShowActionButtons: false,
    daoName: "",
    availableDaos: [],
    karmaDelegatorsUrl: (0, _object.computed)(function () {
      return `https://karmahq.xyz/dao/${this.daoName?.toLowerCase()}/delegators/${this.profile.username}`;
    }),
    setProfile(profile) {
      (0, _object.set)(this, "profile", profile);
    },
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'daoName', window.selectedDao?.[0]?.toUpperCase() + window.selectedDao?.slice(1));
      (0, _object.set)(this, 'availableDaos', this.siteSettings.DAO_names?.split(",").map(name => ({
        name,
        select: () => this.selectDao(name)
      })));
    },
    async didReceiveAttrs() {
      this._super(...arguments);
      await this.fetchProfile();
    },
    selectDao(daoName) {
      if (!daoName) {
        return;
      }
      ;
      if (!this.availableDaos.find(d => d.name === daoName)) {
        return;
      }
      ;
      (0, _object.set)(this, 'daoName', daoName);
      window.selectedDao = daoName;
      this.fetchProfile();
    },
    async fetchProfile() {
      const profile = await _index.default.start(30, {
        SiteSettings: this.siteSettings
      }, "#" + this.wrapperId, this.username);
      this.setProfile(profile);
      (0, _object.set)(this, "shouldShowActionButtons", this.session && profile.username && this.currentUser && profile?.username === this.currentUser?.username);
    }
  }, [["method", "selectDao", [_object.action]], ["method", "fetchProfile", [_object.action]]]));
});