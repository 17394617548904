define("discourse/plugins/karma-score/initializers/karma-stats", ["exports", "discourse/lib/plugin-api", "discourse/plugins/karma-score/lib/stats/index", "discourse/plugins/karma-score/lib/voting-history/index"], function (_exports, _pluginApi, _index, _index2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO: !!Refactor this to ember components
  function bootstrap(_, ctx) {
    const {
      DAO_names
    } = ctx.SiteSettings;
    if (!DAO_names || !DAO_names.length) {
      return;
    }
    window.selectedDao = DAO_names.split(',')?.[0];
    function release() {
      let wrapperId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "#__karma-stats-summary";
      let showing = false;
      const karmaStats = () => {
        const elTrg = $(wrapperId);
        if (!showing && elTrg.length) {
          _index.default.start(0, ctx, wrapperId).then(profile => {
            _index2.default.start(profile, ctx, wrapperId).then(votes => {
              _index2.default.render(votes);
            });
          });
        }
        showing = !!elTrg.length;
      };
      setInterval(karmaStats, 100);
    }
    function userCard() {
      release(".__karma-stats");
    }
    $(() => {
      userCard();
    });
  }
  var _default = _exports.default = {
    name: "karma-stats",
    async initialize(container) {
      // eslint-disable-next-line no-console
      console.info("Karma Score (v1.4.1-beta1)");
      const SiteSettings = container.lookup("site-settings:main");
      if (SiteSettings.Enable_Karma_plugin) {
        (0, _pluginApi.withPluginApi)("0.8.7", bootstrap, {
          SiteSettings,
          container
        });
      }
    }
  };
});