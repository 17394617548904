define("discourse/plugins/karma-score/discourse/components/vote-item", ["exports", "@ember/component", "@ember/service", "@ember/object", "discourse/plugins/karma-score/lib/voting-history/template"], function (_exports, _component, _service, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    router: (0, _service.inject)(),
    vote: {},
    profile: {},
    wrapperId: "__karma-stats-summary",
    icon: (0, _object.computed)(function () {
      return (0, _template.renderVote)(this.vote.choice);
    }),
    item: (0, _object.computed)(function () {
      return (0, _template.default)(this.vote.proposal, this.vote.voteMethod, this.vote.executed, this.vote.choice);
    })
  });
});