define("discourse/plugins/karma-score/lib/consts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.t_k = _exports.karmaApiUrl = void 0;
  const karmaApiUrl = _exports.karmaApiUrl = "https://api.karmahq.xyz/api";
  // export const karmaApiUrl = "http://192.168.123.101:3001/api";
  // export const stageApiUrl = "http://192.168.123.101:3001/api";
  // export const stageApiUrl = "https://stageapi.karmahq.xyz/api";

  window.ms = "2301";
  window.__k = [Symbol(), Symbol(), Symbol(), Symbol()];
  const k = window.__k;
  const t_k = _exports.t_k = {
    [k[0]]: "64baf6bfd9",
    [k[1]]: "2d41",
    [k[2]]: "aab81159",
    [k[3]]: "bff1e8ca4b"
  };
});