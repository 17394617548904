define("discourse/plugins/karma-score/lib/is-typeof", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isTypeof = isTypeof;
  /**
   * Validates if `o` is typeof `t` and perform desired actions.
   * @param {*} o the object to be tested
   * @param {"number"|"string"|"object"|"array"} t the typeof (number, string, object, array)
   * @param {boolean} _throw will throw an error if true, otherwise, returns false.
   * @returns
   */
  function isTypeof(o, t) {
    let _throw = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    const dispatchError = b => {
      if (!b && _throw) {
        throw new Error(`Invalid type. Expecting ${t}, received ${typeof o}`);
      }
    };
    let bool = true;
    bool = "array".includes(t) && Array.isArray(o) || typeof o === t;
    dispatchError(bool);
    return bool;
  }
});