define("discourse/plugins/karma-score/lib/snapshot/singleChoice", ["exports", "discourse/plugins/karma-score/lib/snapshot/BaseScore"], function (_exports, _BaseScore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SingleChoiceVoting extends _BaseScore.BaseScore {
    static isValidChoice(voteChoice) {
      let proposalChoices = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [""];
      return typeof voteChoice === "number" && proposalChoices?.[voteChoice - 1] !== undefined;
    }
    getValidVotes() {
      return this.votes.filter(vote => SingleChoiceVoting.isValidChoice(vote.choice, this.proposal.choices));
    }
    getScores() {
      return this.proposal.choices.map((choice, i) => {
        const votes = this.getValidVotes().filter(vote => vote.choice === i + 1);
        const balanceSum = votes.reduce((a, b) => a + b.balance, 0);
        return balanceSum;
      });
    }
    getScoresByStrategy() {
      return this.proposal.choices.map((choice, i) => {
        const scores = this.strategies.map((strategy, sI) => {
          const votes = this.getValidVotes().filter(vote => vote.choice === i + 1);
          const scoreSum = votes.reduce((a, b) => a + b.scores[sI], 0);
          return scoreSum;
        });
        return scores;
      });
    }
    getScoresTotal() {
      return this.getValidVotes().reduce((a, b) => a + b.balance, 0);
    }
    getChoiceString() {
      return this.proposal.choices[this.selected - 1];
    }
  }
  _exports.default = SingleChoiceVoting;
});