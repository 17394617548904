define("discourse/plugins/karma-score/discourse/components/proposal-item", ["exports", "@ember/component", "@ember/object", "discourse/plugins/karma-score/lib/get-proposal-link", "discourse/plugins/karma-score/lib/mixpanel", "discourse/plugins/karma-score/lib/vote-breakdown"], function (_exports, _component, _object, _getProposalLink, _mixpanel, _voteBreakdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    proposal: {},
    tokenContract: "",
    link: "",
    pointer: "",
    loading: false,
    text: (0, _object.computed)(function () {
      return this.getText(this.proposal);
    }),
    showRedirectButton: (0, _object.computed)(function () {
      return this.proposal.type === "Off-chain" && this.siteSettings.See_on_Snapshot_button || this.proposal.type === "On-chain" && this.siteSettings.See_on_Tally_button;
    }),
    /**
     * @param {import("karma-score").OffChainProposal} proposal
     */
    getText(proposal) {
      return proposal.type === "Off-chain" && moment(proposal.voteStarts).isAfter(moment()) ? `Voting begins ${proposal.voteStarts}` : proposal.endsAt !== null ? (moment(proposal.endsAt).isBefore(moment()) ? "Ended " : "Ends: ") + proposal.endsAt : "";
    },
    redirect() {
      _mixpanel.Mixpanel.reportEvent({
        event: "bannerClick",
        properties: {
          target: this.proposal.type === "Off-chain" ? "Snapshot" : "Tally",
          url: this.link,
          handler: this.currentUser?.username
        }
      });
      window.open(this.link, "_blank");
    },
    getLink() {
      const nLink = (0, _getProposalLink.default)(this.proposal, this.tokenContract);
      (0, _object.set)(this, "link", nLink);
    },
    async getBreakdown() {
      if (this.proposal.type === "Off-chain") {
        (0, _object.set)(this, "loading", true);
        const proposal = {
          ...this.proposal
        };
        const withScores = (0, _voteBreakdown.getVoteBreakdownByProposal)(await (0, _voteBreakdown.getResults)(proposal.space, proposal, proposal.votes));
        (0, _object.set)(this, "proposal", withScores);
        (0, _object.set)(this, "loading", false);
      }
    },
    init() {
      this._super(...arguments);
      this.getLink();
      this.getBreakdown();
    }
  }, [["method", "redirect", [_object.action]]]));
});