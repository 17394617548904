define("discourse/plugins/karma-score/lib/voting-history/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.renderVote = renderVote;
  const voteIcon = {
    no: "https://www.karmahq.xyz/icons/cross-circle.svg",
    yes: "https://www.karmahq.xyz/icons/check-circle.svg",
    empty: "https://www.karmahq.xyz/icons/empty-circle.svg"
  };
  function getIcon() {
    let choice = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "not vote";
    if (!choice || /not vote/gi.test(choice)) {
      return voteIcon.empty;
    }
    if (choice?.toLowerCase?.().substring(0, 2) === "no" || /agai+nst/gi.test(choice)) {
      return voteIcon.no;
    }
    if (/abstain/gi.test(choice)) {
      return voteIcon.empty;
    }
    return voteIcon.yes;
  }
  function renderVote(vote) {
    const voteText = (+vote === 0 ? "No" : +vote === 1) ? "Yes" : vote;
    return `<img src="${getIcon(voteText)}" alt="check-circle">
  <p class="vote-choice">${voteText ?? "Didn't vote"}</p>`;
  }
  var _default = (name, type, executedAt, vote) => `
<div class="voting-item">
    <div class="details">
        <p class="name">${name}</p>
        <div class="status-container">
            <p class="chain">${type}</p>
            <p class="executed">Executed ${executedAt}</p>
        </div>
    </div>
    <div class="decision">
        ${renderVote(vote)}
    </div>
</div>
`;
  _exports.default = _default;
});