define("discourse/plugins/karma-score/discourse/components/vote-reason-form", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "discourse/plugins/karma-score/lib/post-to-topic", "discourse/plugins/karma-score/lib/delete-post", "discourse/plugins/karma-score/lib/voting-history/gql/off-chain-fetcher", "discourse/plugins/karma-score/lib/voting-history/gql/on-chain-fetcher", "discourse/plugins/karma-score/lib/fetch-user-threads", "discourse/plugins/karma-score/lib/karma-api-client", "discourse/plugins/karma-score/lib/update-post", "discourse/plugins/karma-score/lib/get-proposal-link", "discourse/plugins/karma-score/lib/fetch-snapshot-onchain-ids"], function (_exports, _component, _object, _runloop, _postToTopic, _deletePost, _offChainFetcher, _onChainFetcher, _fetchUserThreads, _karmaApiClient, _updatePost, _getProposalLink, _fetchSnapshotOnchainIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    form: {
      recommendation: "",
      summary: "",
      publicAddress: "",
      postId: null
    },
    vote: {},
    submittedVotes: [],
    proposals: [],
    reasons: [],
    threads: [],
    hideButton: true,
    message: "",
    loading: false,
    proposalLoading: false,
    fetched: false,
    visible: false,
    errors: [],
    modalId: "__karma-vote-form-modal",
    threadId: -1,
    proposalId: -1,
    daoName: "",
    onClose: function () {
      (0, _object.set)(this, "visible", false);
    },
    proposalLink: function () {
      const proposal = this.proposals[this.proposalId];
      const link = (0, _getProposalLink.default)(proposal);
      return link ? `[${proposal.title}](${link})` : `### ${proposal.title}`;
    },
    // proposalTitle: computed(function() {
    //   return this.proposal[this.form.proposalId]?.id
    // })

    init() {
      this._super(...arguments);
      this.daoName = window.selectedDao;
    },
    resetForm() {
      (0, _object.set)(this, "form", {
        ...this.form,
        recommendation: "",
        summary: "",
        postId: null
      });
      (0, _object.set)(this, "proposalId", -1);
    },
    dispatchToggleModal() {
      setTimeout(() => {
        // this.toggleModal();
        setTimeout(() => {
          (0, _object.set)(this, "message", "");
          (0, _object.set)(this, "errors", []);
          // this.resetForm();
        }, 250);
      }, 2000);
    },
    async createThread() {
      return false;
    },
    async post() {
      let postId;
      const {
        reason: hasReason
      } = this.proposals[this.proposalId];
      const hasSetReason = !!hasReason;
      const karma = new _karmaApiClient.default(this.siteSettings.DAO_names, this.form.publicAddress);
      const reason = `${this.proposalLink()}

${this.form.recommendation}`;
      try {
        if (hasReason?.postId) {
          postId = hasReason.postId;
          await (0, _updatePost.default)({
            postId,
            body: reason,
            csrf: this.session.csrfToken
          });
        } else {
          const {
            id
          } = await (0, _postToTopic.default)({
            threadId: +this.threadId,
            body: reason,
            csrf: this.session.csrfToken
          });
          postId = id;
        }
      } catch (error) {
        throw new Error("We couldn't post your pitch on Discourse.");
      }
      try {
        await karma.saveVoteReason(this.proposals[this.proposalId].id, {
          ...this.form,
          postId,
          threadId: this.threadId
        }, this.session.csrfToken, hasSetReason);
        (0, _object.set)(this, "postId", postId);
        this.setPostReason(reason);
      } catch (error) {
        if (!hasSetReason && postId) {
          await (0, _deletePost.default)({
            postId,
            csrf: this.session.csrfToken
          });
        }
        throw new Error(`We couldn't send your vote to Karma. ${error.message ? "Rason: " + error.message : ""}`);
      }
    },
    checkErrors() {
      (0, _object.set)(this, "errors", []);
      const raw = this.form.recommendation + this.form.summary;
      const errors = this.errors;
      if (this.threadId === -1) {
        errors.push("Reason thread is required.");
      }
      if (this.proposalId === -1) {
        errors.push("Proposal is required.");
      }
      if (raw.length < 20) {
        errors.push("Your messages should have at least 20 chars.");
      }
      (0, _object.set)(this, "errors", errors);
      return !!errors.length;
    },
    async send() {
      const hasErrors = this.checkErrors();
      if (!hasErrors) {
        (0, _object.set)(this, "loading", true);
        try {
          if (this.threadId === -2) {
            await this.createThread();
          }
          await this.post();
          this.dispatchToggleModal();
          (0, _object.set)(this, "message", "Thank you! Your recommendation was submitted successfully.");
        } catch (error) {
          (0, _object.set)(this, "errors", [error.message]);
        } finally {
          (0, _object.set)(this, "loading", false);
        }
      }
    },
    async fetchThreads() {
      try {
        const threads = await (0, _fetchUserThreads.default)(this.currentUser?.username);
        (0, _object.set)(this, "threads", threads.topic_list.topics.map(topic => ({
          name: topic.fancy_title,
          id: topic.id
        })));
        // this.setDefaultThreadId();
      } catch {}
    },
    async fetchProposals() {
      const {
        daoIds
      } = this.siteSettings;
      const graphqlIds = window.daoIds = window.daoIds ?? daoIds ?? (await (0, _fetchSnapshotOnchainIds.fetchDaoSnapshotAndOnChainIds)(this.daoName));
      let onChain = [];
      if (graphqlIds.onChainId?.length) {
        onChain = await (0, _onChainFetcher.fetchActiveOnChainProposals)([graphqlIds.onChainId].flat(), 500);
      }
      let offChain = [];
      if (graphqlIds.snapshotIds?.length) {
        offChain = await (0, _offChainFetcher.fetchActiveOffChainProposals)([graphqlIds.snapshotIds].flat(), 500);
      }
      const proposals = onChain.concat(offChain).sort((a, b) => moment(a.endsAt).isBefore(moment(b.endsAt)) ? 1 : -1);
      return proposals;
    },
    async fetchVoteReasons() {
      let proposals = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      const karma = new _karmaApiClient.default(this.daoName, this.profile.address);
      try {
        const {
          reasons
        } = await karma.fetchVoteReasons();
        if (reasons && Array.isArray(reasons)) {
          proposals = proposals.map(proposal => {
            const hasReason = reasons.find(reason => reason.proposalId === proposal.id);
            if (hasReason) {
              proposal.reason = hasReason;
              proposal.disabled = true;
            }
            return proposal;
          });
          (0, _object.set)(this, "reasons", reasons);
        }
      } catch {}
      (0, _object.set)(this, "proposals", proposals);
    },
    setPostReason() {
      const proposals = this.proposals.map(p => ({
        ...p
      }));
      proposals[this.proposalId].reason = {
        ...this.form,
        threadId: this.threadId,
        postId: this.postId
      };
      (0, _object.set)(this, "proposals", proposals);
    },
    setDefaultThreadId() {
      let threadId = this.threadId;
      if (this.reasons.length && this.threads.length) {
        if (this.reasons?.[0].threadId) {
          threadId = this.threads.findIndex(t => t.id === this.reasons[0].threadId);
        }
        (0, _object.set)(this, "threadId", threadId);
      }
    },
    async didReceiveAttrs() {
      this._super(...arguments);
      if (this.profile.address) {
        (0, _object.set)(this, "proposalLoading", true);
        const proposals = await this.fetchProposals();
        await this.fetchVoteReasons(proposals);
        (0, _object.set)(this, "proposalLoading", false);
        (0, _object.set)(this, "fetched", true);
        (0, _object.set)(this, "form", {
          ...this.form,
          publicAddress: this.profile.address
        });
      }
      this.fetchThreads();
    },
    hasPostId: (0, _object.computed)(function () {
      return this.form.postId && +this.form.postId !== 0;
    }),
    isOutside(e) {
      if (!$(e.target).closest(".modal-content").length) {
        this.onClose();
      }
    },
    submit(e) {
      e.preventDefault();
      return (0, _runloop.throttle)(this, this.send, 200);
    },
    setFormData(key, data) {
      (0, _object.set)(this, "form", {
        ...this.form,
        [key]: data
      });
    },
    setReason(e) {
      this.setFormData("recommendation", e.target.value);
    },
    setSummary(e) {
      this.setFormData("summary", e.target.value);
    },
    setProposal(e) {
      const proposalId = +e.target.value;
      const {
        reason
      } = this.proposals[proposalId];
      if (reason) {
        (0, _object.set)(this, "form", {
          ...this.form,
          ...reason
        });
      } else {
        (0, _object.set)(this, "form", {
          ...this.form,
          postId: null
        });
      }
      (0, _object.set)(this, "proposalId", proposalId);
    },
    setThreadId(e) {
      const idx = +e.target.value;
      if (idx !== "null") {
        (0, _object.set)(this, "threadId", idx === -2 ? idx : this.threads[idx].id);
      }
    }
  }, [["method", "isOutside", [_object.action]], ["method", "submit", [_object.action]], ["method", "setReason", [_object.action]], ["method", "setSummary", [_object.action]], ["method", "setProposal", [_object.action]], ["method", "setThreadId", [_object.action]]]));
});