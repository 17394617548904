define("discourse/plugins/karma-score/lib/get-proposal-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getProposalLink;
  const {
    BigInt
  } = window;
  function getProposalLink(proposal, tokenContract) {
    if (!proposal) {
      return "";
    }
    let nLink;
    if (proposal.type === "Off-chain") {
      nLink = `https://snapshot.org/#/${proposal.snapshotId}/proposal/${proposal.id}`;
    } else {
      const proposalId = BigInt(proposal.id).toString();
      nLink = tokenContract ? `https://tally.xyz/governance/eip155:1:${tokenContract}/proposal/${proposalId}` : "";
    }
    return nLink;
  }
});