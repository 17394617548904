define("discourse/plugins/karma-score/lib/stats/index", ["exports", "@ember/object", "discourse/plugins/karma-score/lib/shorten-number", "@ember/template", "discourse/plugins/karma-score/lib/consts", "discourse/plugins/karma-score/lib/mixpanel"], function (_exports, _object, _shortenNumber, _template, _consts, _mixpanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Karma stats fetcher
   */
  const KarmaStats = {
    url: _consts.karmaApiUrl,
    daoName: undefined,
    profile: {},
    async fetchUser(userAddress, daoName) {
      if (!(userAddress && typeof userAddress === "string" && daoName && typeof daoName === "string")) {
        throw new Error(`User address and dao name must be typeof string, received ${typeof userAddress}, ${typeof daoName}`);
      }
      const userStats = {
        delegatedVotes: 0,
        daoExp: 0,
        snapshotVotingStats: "0%",
        onChainVotingStats: "0%",
        gitcoinHealthScore: 0
      };
      const url = `${KarmaStats.url}/forum-user/${userAddress}/${daoName}`.toLowerCase();
      try {
        const {
          data
        } = await fetch(url).then(res => res.json());
        this.profile = data ?? {};
        const {
          delegates
        } = data;
        if (delegates) {
          const {
            stats
          } = delegates;
          userStats.delegatedVotes = `
        <a href="https://karmahq.xyz/dao/${daoName.toLowerCase()}/delegators/${data.ensName || data.address}" target="_blank">${(0, _shortenNumber.shortenNumber)(delegates.delegatedVotes || 0)}</a>`;
          userStats.snapshotVotingStats = (stats?.[0]?.offChainVotesPct || 0) + "%";
          userStats.onChainVotingStats = (stats?.[0]?.onChainVotesPct || 0) + "%";
          userStats.daoExp = stats?.[0]?.karmaScore || 0;
          userStats.gitcoinHealthScore = stats?.[0]?.gitcoinHealthScore || 0;
        }
        return userStats;
      } catch (error) {
        return undefined;
      }
    },
    toggleErrorMessage() {
      let hide = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      let wrapperId = arguments.length > 1 ? arguments[1] : undefined;
      const el = $(`${wrapperId} .__has-error`);
      if (el.length) {
        hide ? el.hide() : (el.show(), this.toggleLoading(true, wrapperId), this.toggleScore(true, wrapperId));
      }
    },
    toggleLoading() {
      let hide = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      let wrapperId = arguments.length > 1 ? arguments[1] : undefined;
      const el = $(`${wrapperId} .__loading`);
      if (el.length) {
        hide ? el.hide() : (el.show(), this.toggleErrorMessage(true, wrapperId), this.toggleScore(true, wrapperId));
      }
    },
    toggleScore() {
      let hide = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      let wrapperId = arguments.length > 1 ? arguments[1] : undefined;
      const el = $(`${wrapperId} .__has-score`);
      hide ? el.hide() : (el.show(), this.toggleErrorMessage(true, wrapperId), this.toggleLoading(true, wrapperId));
    },
    getUsername(wrapperId) {
      const el = $(`${wrapperId} #__dao-username`);
      let username = el?.val()?.trim();
      // TODO: find another way to get the user in the page
      if (!username) {
        const url = location.pathname.split("/");
        username = url[2];
      }
      return username;
    },
    getSlots(wrapperId) {
      return {
        delegatedVotes: $(`${wrapperId} #__delegated-votes`),
        daoExp: $(`${wrapperId} #__dao-exp`),
        snapshotVotingStats: $(`${wrapperId} #__snapshot-voting-stats`),
        onChainVotingStats: $(`${wrapperId} #__on-chain-voting-stats`),
        gitcoinHealthScore: $(`${wrapperId} #__health-score`)
      };
    },
    getErrorMessage(msg, daoName) {
      return (0, _template.htmlSafe)(msg.replace("[[KarmaDaoUrl]]", `
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.karmahq.xyz/dao/link/forum?dao=${daoName?.toLowerCase()}"
        >
            Link Wallet
        </a>`));
    },
    async start() {
      let totalTries = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      let ctx = arguments.length > 1 ? arguments[1] : undefined;
      let wrapperId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ".__karma-stats";
      let username = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      const {
        SiteSettings
      } = ctx;
      const daoName = this.daoName = window.selectedDao;
      const {
        User_not_found_message: errMessage,
        rawErrorStr: originalErrorMessage
      } = SiteSettings;
      let rawErrorStr = originalErrorMessage;
      if (!rawErrorStr && errMessage) {
        rawErrorStr = errMessage;
        (0, _object.set)(ctx.SiteSettings, "rawErrorStr", errMessage);
      }
      const user = username || this.getUsername(wrapperId);
      if (user && daoName) {
        if (rawErrorStr && rawErrorStr?.includes?.("[[KarmaDaoUrl]]")) {
          (0, _object.set)(ctx.SiteSettings, "User_not_found_message", this.getErrorMessage(rawErrorStr, daoName));
        }
        this.toggleLoading(false, wrapperId);
        const stats = await KarmaStats.fetchUser(user, daoName);
        if (stats) {
          const wrapper = $(`${wrapperId} .__wrapper`)[0];
          if (wrapper) {
            wrapper.style.display = "initial";
          }
          const slots = KarmaStats.getSlots(wrapperId);
          Object.keys(slots).map(key => {
            const isNum = typeof slots[key] === "number";
            slots[key].html(isNum ? stats[key]?.toLocaleString("en-US") : stats[key]);
          });
          this.toggleScore(false, wrapperId);
        } else if (!(stats && user)) {
          this.toggleErrorMessage(false, wrapperId);
        }
        _mixpanel.Mixpanel.reportEvent({
          event: "profileStats",
          properties: {
            address: this.profile.address
          }
        });
      } else if (totalTries < 30) {
        setTimeout(() => KarmaStats.start(++totalTries, ctx), 250);
      }
      this.profile.username = user;
      return this.profile;
    }
  };
  var _default = _exports.default = KarmaStats;
});